import { Col, Row, Layout, Empty } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Doughnut, Bar } from "react-chartjs-2";

const VitalChart = observer(({ data }) => {
  function getGraphData() {
    return {
      labels: data.labels,
      datasets: [
        {
          label: data.name,
          data: data.data,
          borderColor: data.color,
          backgroundColor: function (context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
              // This case happens on initial chart load
              return null;
            }
            var gradient = ctx.createLinearGradient(0, 0, 0, 220);
            console.log(data.color, " data color");
            if (data.color) {
              gradient.addColorStop(0, data.color + "FF");
              gradient.addColorStop(1, data.color + "00");
            }
            return gradient;
          },
          fill: true,
          pointBackgroundColor: data.color,
          pointRadius: 3,
          type: "line",
          order: 1,
          tension: 0.5,
        },
      ],
    };
  }

  function getOptions() {
    return {
      plugins: {
        title: {
          display: false,
        },
      },
      //responsive: true,

      scales: {
        x: {
          display: true,
          grid: {
            display: false,
          },
        },
        y: {
          grid: { lineWidth: 0.2 },
        },
      },
      maintainAspectRation: false,
    };
  }
  return data ? (
    <Bar data={getGraphData()} height={150} options={getOptions()} />
  ) : (
    <Empty
      style={{ marginTop: "9%", marginLeft: "25%", minHeight: "28vh" }}
      description="Patient's monthly records not available"
    />
  );
});
export default VitalChart;
