import { FaPhoneAlt } from "react-icons/fa";
import { IoChatbubblesSharp } from "react-icons/io5";
import { IoIosVideocam } from "react-icons/io";
import CircleButton from "./CircleButton";
import { Row, Col } from "antd";
const ContactButtons = ({ store, style, phone, className, showVideo = true, showChat = true, showPhone = true, onChat = () => { } }) => {
  const styles = {
    iconContainer: {
      // flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      textAlign: "center",
      flexWrap: "wrap",
    },
    button: {
      backgroundColor: "#23D098",
      // marginHorizontal: "auto",
      marginLeft: "auto",
      marginRight: "auto",
    },
  };
  return (
    <Row align="middle">
      {showPhone && <Col span={6}>
        <div>
          <CircleButton
            style={styles.button}
            onClick={() => {
              // alert("call");
            }}
          >
            <FaPhoneAlt size={20} color={"white"} />
          </CircleButton>
        </div>
      </Col>}
      {showChat && <Col span={12}>
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",

            width: 40,
          }}
        >
          <IoChatbubblesSharp
            size={40}
            color={"#FA644E"}
            onClick={() => {
              // alert("chat");
              onChat()

            }}
          />
        </div>
      </Col>}
      {showVideo && <Col span={6}>
        <CircleButton
          style={styles.button}
          onClick={() => {
            alert("video call");
          }}
        >
          <IoIosVideocam size={25} color={"white"} />
        </CircleButton>
      </Col>}
    </Row>
  );
};

export default ContactButtons;
