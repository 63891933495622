import {
  Col,
  Row,
  Layout,
  Input,
  Dropdown,
  Menu,
  Divider,
  Avatar,
  Badge,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { HiSupport } from "react-icons/hi";
import { IoChatbubblesSharp } from "react-icons/io5";
import { IoMdNotifications } from "react-icons/io";
import NotificationMenu from "../NotificationMenu";
import { DownOutlined } from "@ant-design/icons";
import ProfilePic from "../ProfilePic";
import { profileStore } from "../../dataLayer/stores/pages/profileStore";
import ProfileMenu from "../ProfileMenu";
import CircleButton from "../CircleButton";
import { FaPhoneAlt } from "react-icons/fa";
import PhIcon from "../../assets/Icons/PhIcon";
import { dbstore } from "../../dataLayer/stores/dbStore";

const { Text } = Typography;

const TopNav = observer(({ data }) => {
  const styles = {
    iconContainer: {
      // flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      textAlign: "center",
      flexWrap: "wrap",
    },
    button: {
      backgroundColor: "#23D098",
      // marginHorizontal: "auto",
      marginLeft: "auto",
      marginRight: "auto",
    },
  };

  return (
    <Row style={{ padding: 20, backgroundColor: "#fff" }}>
      <Col span={6}>
        {/* <Input
          size="large"
          width={"auto"}
          bordered={false}
          placeholder="Search Prescription, Doctor Pharmacy"
          prefix={<SearchOutlined style={{ color: "#ccc" }} />}
        /> */}
      </Col>
      <Col span={6} style={{ marginLeft: "auto" }}>
        <div
          style={{
            height: 50,
            backgroundColor: "#efefef",
            borderRadius: 25,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PhIcon />
          <Text
            style={{ marginLeft: "10px" }}
            type={dbstore.isPharmacyOpen ? "success" : "danger"}
            strong
          >
            Pharmacy {dbstore.isPharmacyOpen ? `Open` : `Closed`}
          </Text>
        </div>
      </Col>
      <Col
        span={4}
        style={{
          marginLeft: "auto",
          borderRight: "1px solid #ccc",
          paddingRight: 30,
        }}
      >
        <Row>
          <Col style={{ marginLeft: "auto" }}>
            <Link to="/profile" className={"nav-link"}>
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: 40,
                }}
              >
                <IoChatbubblesSharp
                  title="Help"
                  size={40}
                  color={"#FA644E"}
                  onClick={() => {}}
                  style={{}}
                />
              </div>
              {/* <HiSupport className={"nav-icon"} style={{ color: "#ccc", cursor: "pointer" }} title="Help" /> */}
            </Link>
          </Col>
          {/* <Col>
            <Link to="/messages" className={"nav-link"}>
              <IoChatbubblesSharp
                className={"nav-icon"}
                style={{ color: "#ccc" }}
              />
            </Link>
          </Col>
          <Col>
            <Dropdown overlay={NotificationMenu} trigger={["click"]}>
              <IoMdNotifications
                style={{
                  marginTop: 13.5,
                  cursor: "pointer",
                  color: "#ccc",
                  marginLeft: 13,
                }}
              />
            </Dropdown>
          </Col> */}
        </Row>
      </Col>
      <Col span={5}>
        <Row>
          <Col span={24}>
            <Dropdown overlay={<ProfileMenu />} trigger={["click"]}>
              <div
                style={{
                  cursor: "pointer",
                  color: "#aaa",
                  marginLeft: 13,
                }}
              >
                <span style={{ color: "#333", marginRight: 15 }}>
                  {profileStore.comp1Data.name}
                </span>
                <DownOutlined />
                <Avatar
                  shape="circle"
                  style={{ marginLeft: 25 }}
                  size={45}
                  icon={<ProfilePic avatar={profileStore.comp1Data.avatar} />}
                />
              </div>
            </Dropdown>
          </Col>
        </Row>
      </Col>
    </Row>
  );
});
export default TopNav;
