import { Col, Row } from "antd";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { refillRequestActionStore } from "../../dataLayer/stores/actions/refillRequestActionStore";
import { dbstore } from "../../dataLayer/stores/dbStore";
import { refillRequestStore } from "../../dataLayer/stores/pages/refillRequestStore";
import RRList from "../RRList";
import RxDetails from "../RxDetails";
import Triangle from "../Triangle";
var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const Refills = observer(({ props }) => {
  useEffect(() => {
    dbstore.getReportStats();
  }, []);

  return (
    <>
      <Row>
        <Col span={8}>
          <div
            style={{ backgroundColor: "#fff", marginTop: 15, maxHeight: "82.5vh" }}
            className="drp-card"
          >
            <RRList
              data={refillRequestStore.comp1Data}
              onClick={(id) => {
                refillRequestActionStore.setSelected(id);
              }}
            />
          </div>
        </Col>
        <Col span={16}>
          <div
            style={{
              backgroundColor: "#fff0",
              minHeight: "70vh",
              maxHeight: "90vh",
              marginTop: 15,
              marginLeft: 10,
            }}
          >
            <Row style={{ padding: 20 }} className="drp-card">
              <RxDetails data={refillRequestStore.comp2Data} />
              <Triangle
                style={{
                  position: "absolute",
                  top: "25%",
                  left: 0,
                }}
              />
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
});
export default Refills;
