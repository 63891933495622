import { Col, Row, Layout } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Document, Page, pdfjs } from "react-pdf";
import dummypdf from "../assets/dummy.pdf";
const PdfDisplay = observer(({ props }) => {
  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <>
      <Document file={dummypdf} onLoadSuccess={onDocumentLoadSuccess}>
        {Array(numPages)
          .fill(0)
          .map((v, i) => {
            return <Page pageNumber={i + 1} />;
          })}
      </Document>
    </>
  );
});
export default PdfDisplay;
