/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { dbstore } from "../dataLayer/stores/dbStore";
import { message } from "antd";
import { useGetQueryStringParams } from "../hooks/CustomHooks";

const PtInfo = observer(({ data }) => {

  let history = useHistory();
  const patientId = useGetQueryStringParams();

  useEffect(() => {

    if (dbstore.selectedPatientId) {
      dbstore.getpatientReports(patientId);
    }
  }, [, dbstore.selectedPatientId]);

  return (
    <svg minWidth={"auto"} height={141.4} viewBox="0 0 798.821 145.4">
      <defs>
        <style>
          {
            "._PtInfoa{fill:#e3e3e3;}._PtInfob{fill:#6c7b8a;font-family:Nunito-Regular, Nunito;}._PtInfob,._PtInfoc{font-size:20px;}._PtInfoc,._PtInfod{fill:#140f26;}._PtInfoc,._PtInfod,._PtInfoh{font-family:Nunito-SemiBold, Nunito;font-weight:600;}._PtInfod{font-size:13px;}._PtInfoe{fill:url(#a);}._PtInfof,._PtInfog{fill:#fff;}._PtInfof{font-size:18px;font-family:Montserrat-Medium, Montserrat;font-weight:500;letter-spacing:0.091em;}._PtInfoh,._PtInfoi{fill:#272d5c;}._PtInfoh{font-size:9px;}._PtInfoj{fill:#fe5431;}.shadowFilter{filter:  drop-shadow(0px 0px 10px #bbb);}"
          }
        </style>
        <filter id="f4" x="-20%" y="-60%" width="200%" height="200%">
          <feDropShadow dx="0.2" dy="0.4" stdDeviation="7" floodColor="#ddd" />
        </filter>

        <linearGradient
          id="a"
          x1={0.125}
          y1={-0.865}
          x2={0.718}
          y2={1.268}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#2dc9eb" />
          <stop offset={1} stopColor="#14d2b8" />
        </linearGradient>
      </defs>
      <g width={150} transform="translate(-3664.5 -3470.6)">
        {/* <g transform="translate(4384.5 3470.6)">
          <rect className="_PtInfoa" width={78.821} height={90.206} rx={15} />
          <foreignObject width={75} height={85} className="shadowFilter">
            <BubbleStatusCard />
          </foreignObject>
        </g> */}
        <text className="_PtInfob" transform="translate(3767.683 3516.605)">
          <tspan x={0} y={20}>
            {`Last Visited ${data.visitedDays} Days ago `}
          </tspan>
        </text>
        <text className="_PtInfoc" transform="translate(3767.683 3505.986)">
          <tspan x={0} y={0}>
            {`${data.pname},`} {`${data.page}, ${data.pgender}`}
          </tspan>
        </text>
        <text className="_PtInfod" transform="translate(3917.006 3504.02)">
          <tspan x={0} y={0}></tspan>
        </text>
        <circle
          className="_PtInfoe"
          cx={37.75}
          cy={37.75}
          r={37.75}
          transform="translate(3664.5 3475.5)"
        />
        <text className="_PtInfof" transform="translate(3719.5 3519.423)">
          <tspan x={-28.831} y={0}>
            {`${data.alt}`}
          </tspan>
        </text>
        <g
          transform="translate(3757 3575.534)"
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (dbstore.patientReports) {
              history.push(`/patientreports?patientId=${dbstore.selectedPatientId}`);
            } else {
              message.info("Reports not available for this patient");
            }
          }}
        >
          <rect
            className="_PtInfog"
            width={97}
            height={36}
            rx={18}
            transform="translate(0 0.466)"
            filter="url(#f4)"
          />
          <text className="_PtInfoh" transform="translate(33 21.466)">
            <tspan x={0} y={0}>
              {"Reports"}
            </tspan>
          </text>
          <g transform="translate(12.415 8.877)">
            <path
              className="_PtInfoi"
              d="M32.245,6.638a1.238,1.238,0,0,0,.862-.35l1.228.614a1.219,1.219,0,0,0-.015.151,1.245,1.245,0,1,0,2.489,0,1.23,1.23,0,0,0-.125-.533L38.35,4.854a1.23,1.23,0,0,0,.533.125,1.246,1.246,0,0,0,1.245-1.245,1.226,1.226,0,0,0-.062-.369L41.513,2.28a1.244,1.244,0,1,0-.556-1.035,1.226,1.226,0,0,0,.062.369L39.572,2.7a1.239,1.239,0,0,0-1.809,1.568L36.1,5.933a1.223,1.223,0,0,0-1.395.225l-1.228-.614a1.219,1.219,0,0,0,.015-.151,1.245,1.245,0,1,0-1.245,1.245Zm0,0"
              transform="translate(-30.143 0)"
            />
            <path
              className="_PtInfoi"
              d="M13.747,159.183H13.3v-8.768a.415.415,0,0,0-.415-.415H11.23a.415.415,0,0,0-.415.415v8.768h-.83V152.9a.415.415,0,0,0-.415-.415H7.911a.415.415,0,0,0-.415.415v6.279h-.83v-2.96a.415.415,0,0,0-.415-.415H4.591a.415.415,0,0,0-.415.415v2.96h-.83v-4.619a.415.415,0,0,0-.415-.415H1.272a.415.415,0,0,0-.415.415v4.619H.415a.415.415,0,1,0,0,.83H13.747a.415.415,0,1,0,0-.83Zm0,0"
              transform="translate(0 -145.851)"
            />
          </g>
        </g>
        {/* <g
          transform="translate(3987.687 3576.602)"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/calendar?patientId=${dbstore.selectedPatientId}`);
          }}
        >
          <rect
            className="_PtInfog"
            width={91}
            height={34}
            rx={17}
            transform="translate(0.314 0.398)"
            filter="url(#f4)"
          />
          <text className="_PtInfoh" transform="translate(34.314 19.398)">
            <tspan x={0} y={0}>
              {"RxCalender"}
            </tspan>
          </text>
        </g> */}
        <g
          transform="translate(3871.275 3575)"
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (dbstore.patientReports) {
              history.push(`/vitals?patientId=${dbstore.selectedPatientId}`);
            } else {
              message.info("Vitals not available for this patient");
            }
          }}
        >
          <rect
            className="_PtInfog"
            width={100}
            height={37}
            rx={18.5}
            transform="translate(-0.275)"
            filter="url(#f4)"
          />
          <text className="_PtInfoh" transform="translate(36.725 22)">
            <tspan x={0} y={0}>
              {"Vitals"}
            </tspan>
          </text>
          <g transform="translate(17.089 12.14)">
            <g transform="translate(0 0)">
              <path
                className="_PtInfoj"
                d="M10.478,50.278A3.815,3.815,0,0,0,7.131,52.26a3.816,3.816,0,0,0-3.347-1.982,3.817,3.817,0,0,0-3.3,2.007A4.865,4.865,0,0,0,.6,56.7l0,.009H3.728l.717-1.291a.383.383,0,0,1,.669,0L6.43,57.774l2.036-4.285a.383.383,0,0,1,.706.034l1.158,3.189h3.327l0-.009a4.865,4.865,0,0,0,.113-4.42A3.82,3.82,0,0,0,10.478,50.278Z"
                transform="translate(0 -50.278)"
              />
              <path
                className="_PtInfoj"
                d="M92.49,403.239l-.711-1.961-1.884,3.966a.384.384,0,0,1-.682.023l-1.369-2.449-.265.476a.385.385,0,0,1-.335.2H84.354a26.961,26.961,0,0,0,5.575,5.046.461.461,0,0,0,.527,0,27.05,27.05,0,0,0,5.575-5.046H92.851A.384.384,0,0,1,92.49,403.239Z"
                transform="translate(-83.062 -395.901)"
              />
            </g>
          </g>
        </g>
        {/* Calendar Icon
        <path
          d="M0,13.218a1.368,1.368,0,0,0,1.367,1.367H11.4a1.368,1.368,0,0,0,1.367-1.367V5.47H0ZM9.116,7.635a.343.343,0,0,1,.342-.342H10.6a.343.343,0,0,1,.342.342v1.14a.343.343,0,0,1-.342.342H9.458a.343.343,0,0,1-.342-.342Zm0,3.646a.343.343,0,0,1,.342-.342H10.6a.343.343,0,0,1,.342.342v1.14a.343.343,0,0,1-.342.342H9.458a.343.343,0,0,1-.342-.342ZM5.47,7.635a.343.343,0,0,1,.342-.342h1.14a.343.343,0,0,1,.342.342v1.14a.343.343,0,0,1-.342.342H5.812a.343.343,0,0,1-.342-.342Zm0,3.646a.343.343,0,0,1,.342-.342h1.14a.343.343,0,0,1,.342.342v1.14a.343.343,0,0,1-.342.342H5.812a.343.343,0,0,1-.342-.342ZM1.823,7.635a.343.343,0,0,1,.342-.342H3.3a.343.343,0,0,1,.342.342v1.14a.343.343,0,0,1-.342.342H2.165a.343.343,0,0,1-.342-.342Zm0,3.646a.343.343,0,0,1,.342-.342H3.3a.343.343,0,0,1,.342.342v1.14a.343.343,0,0,1-.342.342H2.165a.343.343,0,0,1-.342-.342ZM11.4,1.823H10.028V.456A.457.457,0,0,0,9.572,0H8.66A.457.457,0,0,0,8.2.456V1.823H4.558V.456A.457.457,0,0,0,4.1,0H3.191a.457.457,0,0,0-.456.456V1.823H1.367A1.368,1.368,0,0,0,0,3.191V4.558H12.763V3.191A1.368,1.368,0,0,0,11.4,1.823Z"
          transform="translate(3999.328 3586.214)"
        /> */}
      </g>
    </svg>
  );
});

export default PtInfo;
