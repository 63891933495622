import { Row } from "antd";
import React from "react";
import { observer } from "mobx-react";
import RRItem from "./RRItem";
const RRList = observer(({ data, onClick = () => {} }) => {
  return (
    <>
      <Row style={{ overflowY: "scroll", paddingRight: 5, maxHeight: "78vh" }}>
        {data.arr.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <RRItem data={item} onClick={onClick} />
            </React.Fragment>
          );
        })}
      </Row>
    </>
  );
});
export default RRList;
