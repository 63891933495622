
import _ from "lodash";
import {
  makeAutoObservable,
} from "mobx";
import moment from "moment";
import { refillRequestActionStore } from "../actions/refillRequestActionStore";
import { dbstore } from "../dbStore";

export default class Store {

  comp1 = {}
  comp2 = {}
  constructor() {
    makeAutoObservable(this);
  }

  get comp1Data() {
    if (!dbstore.refillRequests) {
      return
    }
    var obj = { arr: [] };
    var arr = dbstore.refillRequests
    arr.forEach(item => {
      var o = { refills: item.refills.length, dname: item.docname, pname: item.patname, id: item._id }
      obj.arr.push(o)
    });
    if (obj.arr.length > 0) {
      let selectedRefillRequest = refillRequestActionStore?.comp1?.selected || obj.arr[0].id;
      refillRequestActionStore.setSelected(selectedRefillRequest);
    }
    Object.assign(this.comp1, obj)
    return this.comp1
  }
  get comp2Data() {

    if (!refillRequestActionStore.comp1.selected) {
      return
    }
    if (!dbstore.rxDetails) {
      return
    }
    var obj = {};
    var rx = _.find(dbstore.refillRequests, { _id: refillRequestActionStore.comp1.selected });

    dbstore.setSelectedPatientId(rx.patid);
    obj = {
      rxname: rx.name, docname: rx.docname, 
      rxstartdate: moment(rx.filldate).format('DD MMM YYYY'),
      livePeriod: moment().diff(moment(rx.filldate), 'days') + 'days',
      rxstatus: rx.status,
      rxno: rx.rxno,
      refillNum: rx.refillno,
      refillApprovalStatus: rx.refillApprovalStatus,
      refillApprovalDate: rx.refillApprovalDate,
      reason: rx.reason,
      docname: rx.docname,
      lastrefill: moment(rx.filldate).format('DD MMM YYYY'), 
      rxenddate: moment(rx.expirydate).format('DD MMM YYYY'),
      refills: _.filter(rx.refills, { status: 'pending' }).length,
      rxquantity: rx.quantity, pname: rx.patname, 
      pname: rx.patname, 
      page: moment().diff(rx.patdob, "years", false),
      gender: rx.patgender,
      type: 'rr',
    }
    Object.assign(this.comp2, obj)
    return this.comp2
  }
}

const refillRequestStore = new Store();
export { refillRequestStore };

