import { Link } from "react-router-dom";
import { Col, Container, Image, Row } from "react-bootstrap";
import logo from "../../assets/images/logo@2x.png";
import logoName from "../../assets/images/logoname@2x.png";
import DrNavLink from "./NavLink";
import patientIcon from "../../assets/images/patient.png";
import { HiOutlineHome, HiSupport } from "react-icons/hi";
import { RiBarChart2Fill, RiMailLine, RiBarcodeLine } from "react-icons/ri";
import { IoPersonOutline } from "react-icons/io5";
import { FaRegCalendarAlt } from "react-icons/fa";
const SideNav = (props) => {
  return (
    <div
      className="p-0 bg-white full-height pt-3"
      style={{ overflow: "hidden" }}
    >
      <Link to="/" className="navbar-brand  mr-0 mb-2">
        <Container>
          <Row className="ml-0 mr-0">
            <Col xs={12}>
              <Image src={logo} fluid />
            </Col>
            <Col xs={12}>
              <Image src={logoName} fluid />
            </Col>
          </Row>
        </Container>
      </Link>
      <DrNavLink to="/home" title={"Home"} Icon={HiOutlineHome}></DrNavLink>
      <DrNavLink
        to="/refills"
        title={"Refill Requests"}
        Icon={RiBarChart2Fill}
      ></DrNavLink>
      <DrNavLink
        to="/priorAuth"
        title={"Prior Authorizations"}
        Icon={RiMailLine}
      ></DrNavLink>
      <DrNavLink
        to="/patients"
        title={"Patients"}
        Icon={() => <Image src={patientIcon} />}
      ></DrNavLink>
      <DrNavLink
        to="/profile"
        title={"Profile"}
        Icon={IoPersonOutline}
      ></DrNavLink>
      <DrNavLink to="/help" title={"Help Center"} Icon={HiSupport}></DrNavLink>
    </div>
  );
};

export default SideNav;
