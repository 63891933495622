
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import Home from "../routes/home.js"

import Refills from "../routes/refills.js"

import PdfView from "../routes/pdfView.js"

import PriorAuth from "../routes/priorAuth.js"

import Patients from "../routes/patients.js"

import PatientReports from "../routes/patientReports.js"


import Vitals from "../routes/vitals.js"

import VitalDetails from "../routes/vitalDetails.js"

import Reports from "../routes/reports.js"

import Profile from "../routes/profile.js"


import Help from "../routes/help.js"
import PatientCalendar from "../routes/patientCalendar.js";
import TechnicianReports from "../routes/technicianReports.js";

const RouteSwitcher = (props) => {
    let { path, url } = useRouteMatch();

    return (
        <Switch>

            <Route path="/home" exact>
                <Home />
            </Route>

            <Route path="/refills" exact>
                <Refills />
            </Route>

            <Route path="/pdfView" exact>
                <PdfView />
            </Route>

            <Route path="/priorAuth" exact>
                <PriorAuth />
            </Route>

            <Route path="/patients" exact>
                <Patients />
            </Route>

            <Route path="/patientReports" exact>
                <PatientReports />
            </Route>

            <Route path="/calendar" exact>
                <PatientCalendar />
            </Route>

            <Route path="/vitals" exact>
                <Vitals />
            </Route>

            <Route path="/vitalDetails" exact>
                <VitalDetails />
            </Route>

            <Route path="/reports" exact>
                <Reports />
            </Route>

            <Route path="/profile" exact>
                <Profile />
            </Route>

            {/* <Route path="/messages" exact>
                <Messages />
            </Route> */}

            <Route path="/help" exact>
                <Help />
            </Route>
            <Route path="/technicianReports" exact>
                <TechnicianReports />
            </Route>
            <Route exact path="/">
                <Redirect to={"/home"} />
            </Route>
            <Route path="/">
                <>Page Not Found</>
            </Route>
        </Switch>
    );
};

export default RouteSwitcher;
