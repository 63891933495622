import { Box, RadioButtonGroup } from "grommet";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { Colours } from "../Colours";
import ComplianceBubble from "./ComplianceBubble";
import ReportsChart from "./ReportsChart";
import _ from "lodash";

const ReportStatsCard = observer(({ data }) => {
  return (
    <Card
      className={"row m-0"}
      style={{
        width: "100%",
        marginTop: "10px",
        marginBottom: "10px",
        borderRadius: 30,
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
        minHeight: 300,
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <div
        className={"col-12 col-lg-4"}
        style={{
          minHeight: 300,
          height: "100%",
          flex: "1 1 auto",
          padding: "1.25rem",
        }}
      >
        <div
          className={"text-center"}
          style={{
            fontFamily: "Nunito-Light",
            fontSize: 25,
            color: Colours.primaryText,
          }}
        >
          Pill Compliance
        </div>
        <ComplianceBubble compScale={0.75} percentage={data.percentage} />
      </div>
      <div className={"col-12 col-lg-8 row"}>
        <div
          className={"col-12 pt-2 pb-2"}
          style={{
            fontFamily: "Nunito-Regular",
            fontSize: 18,
            color: Colours.primaryText,
          }}
        >
          {/* Your Everyday Resoponse */}
        </div>
        <div
          className={"col-10"}
          style={{
            minHeight: 300,
            flex: "1 1 auto",
          }}
        >
          <ReportsChart data={data.graphData} />
        </div>
        <div
          className={"col-2"}
          style={{
            minHeight: 300,
            flex: "1 1 auto",
          }}
        >
          <GrommetButtonGroup onChange={data.onChange} />
        </div>
      </div>
    </Card>
  );
});

const GrommetToggleButton = (option, { checked, hover }) => {
  return (
    <Box
      className={"col-12 pb-1"}
      style={{
        width: "90px",
        textAlign: "center",
        borderBottom: "1px solid grey",
        ...(checked && { borderBottom: "1px solid #3B86FF", color: "#3B86FF" }),
      }}
    >
      {option}
    </Box>
  );
};

const GrommetButtonGroup = observer(({ onChange = () => {} }) => {
  const [value, setValue] = useState("Yearly");
  return (
    <RadioButtonGroup
      gap="medium"
      name="doc"
      className="mt-5"
      style={{ fontSize: 16 }}
      options={["Daily", "Weekly", "Monthly", "Yearly"]}
      value={value}
      onChange={(event) => {
        setValue(event.target.value);
        onChange(event.target.value);
      }}
      children={GrommetToggleButton}
    />
  );
});

export default ReportStatsCard;
