import { Col, Row } from "antd";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { dbstore } from "../../dataLayer/stores/dbStore";
import { patientReportsStore } from "../../dataLayer/stores/pages/patientReportsStore";
import { useGetQueryStringParams } from "../../hooks/CustomHooks";

import ReportStatsCard from "../ReportStatsCard";
var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const PatientReports = observer(({ props }) => {
  const patientId = useGetQueryStringParams();
  const [reportCardData, setReportCardData] = useState({});

  useEffect(() => {
    dbstore.getpatientReports(patientId);
  }, []);

  useEffect(() => {
    setReportCardData(patientReportsStore.comp1Data);
  }, [, patientReportsStore.comp1, patientReportsStore.selectedPeriod])

  return (
    <>
      {!dbstore.loading && (
        <Row gutter={[8, 8]} style={{ width: "100%", overflow: "hidden" }}>
          <Col span={24}>
            <div style={box}>
              <ReportStatsCard data={reportCardData} />
            </div>
          </Col>
        </Row>
      )}
    </>
  );
});
export default PatientReports;
