
import {
  makeAutoObservable,
} from "mobx";
import { dbstore } from "../dbStore";

export default class Store {

  comp1 = {}
  comp2 = {}
  comp3 = {}
  comp4 = {}
  comp5 = {}
  comp6 = {}
  constructor() {
    makeAutoObservable(this);
  }

  get comp1Data() {
    var obj = {}
    if (dbstore.doctorDetails) {
      console.log("jsbfwsbh::::::::::", dbstore.doctorDetails)
      obj = {
        name: dbstore.doctorDetails.name,
        age: dbstore.doctorDetails.age,
        gender: dbstore.doctorDetails.gender,
        phone: dbstore.doctorDetails.phone,
        avatar: dbstore.doctorDetails.avatar,
        email: dbstore.doctorDetails.email,
        address: Object.values(dbstore.doctorDetails.address).join(",\n"),
        signature: dbstore.doctorDetails.signature

      }

      // obj.address = Object.values(obj.address || {}).join(",\n") || "N/A"
    }
    Object.assign(this.comp1, obj)
    console.log(this.comp1)
    return this.comp1
  }
  get comp2Data() {
    return this.comp2
  }
  get comp3Data() {
    return this.comp3
  }
  get comp4Data() {
    if (!dbstore.doctorDetails) {
      return
    }
    if (!dbstore.doctorDetails.settings) { return }
    var obj = dbstore.doctorDetails.settings;
    Object.assign(this.comp4, obj);
    return this.comp4
  }
  get comp5Data() {
    return this.comp5
  }
  get comp6Data() {
    return this.comp6
  }
}

const profileStore = new Store();
export { profileStore };

