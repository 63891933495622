import React from "react";
import complianceBubbleAnim from "../assets/animations/complianceBubbleAnimation.json";
import Lottie from "react-lottie";
import { Colours } from "../Colours";
import { round } from "lodash";
import { observer } from "mobx-react";

const ComplianceBubble = observer(
  ({
    store,
    showPercentage = true,
    style,
    bubbleStyle,
    percentage = 0,
    scale = 1,
    mini = false,
    medium = false,
    marginLeft = 0,
  }) => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: complianceBubbleAnim,
    };

    // console.log({ compScale, percentage });
    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontFamily: "Nunito-Bold",
          fontSize: mini ? 10 * scale : medium ? 20 * scale : 60 * scale,
          color: Colours.primaryText,
          marginLeft: marginLeft,
        }}
      >
        <p
          className="text-center"
          style={{
            position: "absolute",
            top: "10%",
            color: Colours.primaryText,
          }}
        >
          {showPercentage && (percentage == NaN || percentage == "-")
            ? "0%"
            : `${round(percentage, 2)}%`}
        </p>
        <Lottie
          options={{ ...defaultOptions }}
          style={{ width: `${60 * scale}%`, ...bubbleStyle }}
          // height={`${30 * compScale}vh`}
          // width={`${30 * compScale}vh`}
        />
      </div>
    );
  }
);

export default ComplianceBubble;
