import "./App.css";
import SideNav from "./components/nav/SideNav";
import { BrowserRouter as Router } from "react-router-dom";
import { Row, Col, Layout, Affix, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useWindowSize } from "./hooks/CustomHooks";
import RouteSwitcher from "./components/nav/routeSwitcher";
import { dbstore } from "./dataLayer/stores/dbStore";
import TopNav from "./components/nav/TopNav";
import { LoadingOutlined } from "@ant-design/icons";
import Login from "./components/routes/Login";
import GetTheApps from "./components/GetTheApps";
import { useEffect } from "react";
import HelpStore from "./dataLayer/stores/pages/help";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const App = observer(() => {
  const [width, height] = useWindowSize();

  useEffect(() => {
    dbstore.getData();
  }, []);

  useEffect(() => {
    if(dbstore.login && !dbstore.globalRefresh && dbstore?.assignedTechnician?._id) {
      HelpStore.statusListener(dbstore.assignedTechnician._id);
    }
  }, [, dbstore.login, dbstore.globalRefresh, dbstore.assignedTechnician]);
  
  return (
    <>
      {width <= 1270 ? (
        <GetTheApps />
      ) : (
        <Router basename="/doctor">
          {!dbstore.login && <Login />}
          {dbstore.login && (
            <Layout style={{ minHeight: "100vh" }}>
              <Row style={{ maxWidth: 1920 }}>
                <Col span={4}>
                  <Affix>
                    <SideNav />
                  </Affix>
                </Col>
                <Col span={20}>
                  <Row>
                    <Col span={24}>
                      <Affix>
                        <TopNav />
                      </Affix>
                    </Col>
                  </Row>
                  <Spin
                    tip="Loading..."
                    spinning={dbstore.loading}
                    indicator={antIcon}
                  >
                    <Row
                      style={{
                        padding: 50,
                        paddingTop: 20,
                        paddingRight: 70,
                        paddingLeft: 70,
                      }}
                    >
                      <Col span={24}>
                        <RouteSwitcher />
                      </Col>
                    </Row>
                  </Spin>
                </Col>
              </Row>
            </Layout>
          )}
        </Router>
      )}
    </>
  );
});
export default App;
