
import { Col, Row } from 'antd'
import { observer } from 'mobx-react';
import { useEffect } from 'react'
import { vitalsActionsStore } from '../../dataLayer/stores/actions/vitalsActionsStore';
import { dbstore } from '../../dataLayer/stores/dbStore';
import { vitalsStore } from '../../dataLayer/stores/pages/vitalsStore';
import { useGetQueryStringParams } from '../../hooks/CustomHooks';
import VitalCardGroup from '../VitalCardGroup';
import VitalChart from '../VitalChart';
import VitalRecords from '../VitalRecords';
var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};

const Vitals = observer(({ props }) => {

  const patientId = useGetQueryStringParams();

  useEffect(() => {
    dbstore.getpatientReports(patientId);
  }, [, vitalsStore.comp1Data, vitalsStore.comp2Data, vitalsStore.comp3Data]);

  return (
    <>
      <Row gutter={[8, 8]} style={{ width: '100%' }}>
        <Col span={24}>
          <div style={box}>
            <VitalCardGroup data={vitalsStore.comp1Data} onClick={(key) => vitalsActionsStore.setComp1(key)} />
          </div>
        </Col>
        <Col span={12}>
          <div style={box}>
            <Row style={{ padding: 20 }} className="drp-card">
              <VitalChart data={vitalsStore.comp2Data} /></Row>
          </div>
        </Col>
        <Col span={12}>
          <div style={box}>
            <VitalRecords limit={5} data={vitalsStore.comp3Data} />
          </div>
        </Col>
      </Row>
    </>
  );
});
export default Vitals;