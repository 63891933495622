import { Row, Table, Tag, Typography } from "antd";
import React from "react";
import { observer } from "mobx-react";
import { dbstore } from "../dataLayer/stores/dbStore";

const { Text } = Typography;

const PtRxList = observer(({ data }) => {
  const columns = [
    {
      title: "Medication Name",
      dataIndex: "rxname",
      key: "rxname",
      sorter: (a, b) => a.rxname.length - b.rxname.length,
      render: (_, { rxname, quantity }) => {
        return `${rxname} (${quantity})`;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 250,
      sorter: (a, b) => a.status.length - b.status.length,
      render: (
        _,
        { date, status, transactionStatus, statusCode, transactionStatusCode }
      ) => {
        // for statusCodes and transactionStatusCodes refer to the rxEvents API documentation
        let color =
          transactionStatusCode === 10 || transactionStatusCode === 11
            ? "#008000" //green
            : transactionStatusCode === 9 ||
              transactionStatusCode === 12 ||
              transactionStatusCode === 16
            ? "#ff0000" //red
            : "#0000ff"; //blue
        return (
          <>
            <Text style={{ color, fontSize: "14px" }}>
              {status} ({transactionStatus})
            </Text>
            <p style={{ fontSize: "14px" }}>{date}</p>
          </>
        );
      },
    },
    {
      title: "Doctor",
      dataIndex: "doctorName",
      key: "doctorName",
      width: 130,
      sorter: (a, b) => a.doctorName.length - b.doctorName.length,
    },
  ];

  return (
    <Table
      loading={dbstore.globalRefresh}
      columns={columns}
      dataSource={data.arr}
      bordered={false}
      sticky
      scroll={{ y: "50vh" }}
    />
  );
});
export default PtRxList;
