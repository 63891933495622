import { makeAutoObservable } from "mobx";
import moment from "moment";

import {
    getFirestore,
    collection,
    addDoc,
    query,
    orderBy,
    limit,
    onSnapshot,
    setDoc,
    updateDoc,
    doc,
    serverTimestamp,
    where,
    getDocs,
} from 'firebase/firestore';
import { dbstore } from "../dbStore";
// import { profileStore } from "./profileStore";

// var db = getFirestore();
class Store {
  constructor() {
    makeAutoObservable(this, {});
  }

  db = null;
  messages = [
    // {
    //     content: "Loren Iposum Loren Iposum Loren Iposum Loren Iposum Loren Iposum Loren Iposum Loren Iposum",
    //     user: 0,
    //     time: "8.48PM"
    // },
  ];
  users = [
    // {
    //     id: "1",
    //     name: "doctor 1",
    //     age: "20",
    //     gender: "M"
  ];
  convId = null;
  currentUser = null;
  currentUserName = null;
  currentUserType = null;
  conversations = [];
  retrieved = false;
  msgSkip = 0;
  msgLimit = 10;
  listening = false;
  isStatusListenerListening = false;

  setDb = (db) => {
    this.db = db;
  };

  setCurrentUserType = (type) => {
    this.currentUserType = type;
  };

  setCurrentUserName = (name) => {
    this.currentUserName = name;
  };

  setCurrentUser = async (id) => {
    this.convId = null;
    this.currentUser = id;
    await this.getConversations();
    //check if id exists in conversation
    let index = this.conversations.findIndex((x) => x._id === id);
    if (index === -1) {
      this.messages = [];
      var obj = {
        _id: id,
        name: this.currentUserName,
      };
      // var temp = this.conversations;
      // temp.push(obj);
      // this.conversations = []
      // Object.assign(this.conversations, temp)
      this.users.push(obj);
    } else {
      this.convId = this.conversations[index].convId;
      await this.getMessages();
    }
  };

  sendNewMessage = async (msg) => {
    dbstore.checkSession();

    var raw = {};
    raw.from = dbstore.decodedUserId;
    raw.msg = msg;
    if (this.convId) {
      raw.convId = this.convId;
    } else {
      // raw.fromName = profileStore.comp1Data.name;
      // raw.fromName = "Admin"
      raw.toName = this.currentUserName;
      raw.to = this.currentUser;
      raw.fromType = "technician";
      raw.toType = this.currentUserType;
    }

    var path = "/chat/send/message";

    var res = await fetch(`${dbstore.endpoint}${path}`, {
      method: "post",
      // cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(raw),
    });

    res = await res.json();
    this.convId = res.convId;

    var index = this.users.findIndex((x) => x._id === this.currentUser);
    if (index != -1) {
      if (!this.users[index].convId) {
        this.users[index].convId = res.convId;
      }
    }

    console.log(this.convId, "response");

    setDoc(doc(this.db, "listeners", this.currentUser), {
      content: msg,
      user: 1,
      time: moment().format("h:mm a"),
      convId: this.convId || "",
    });

    this.messages.push({
      content: msg,
      user: 1,
      time: moment().format("h:mm a"),
    });
  };

  onMessageReceived(msg) {
    this.messages.push({
      content: msg,
      user: 0,
      time: moment().format("h:mm a"),
    });
  }

  handleSearch(e) {
    console.log("handle search called");
    console.log(e.target.value);
    dbstore.setPatientChatFilter("name", e.target.value);
    dbstore.getpatientMessageDetails(true, 0, true);
  }

  handleFilter() {
    console.log("handle fillter called");
  }

  handleUserClick = async (user) => {
    console.log(user._id);
    this.currentUser = user._id;
    this.currentUserName = user.name;
    this.currentUserType = user.type;
    this.convId = user.convId;
    this.getMessages();
  };

  getConversations = async () => {
    dbstore.checkSession();

    var path = "/chat/conversations";
    var options = {
      method: "get",

      credentials: "include",
      // headers: { "Content-Type": "application/json" },
    };
    options["cache"] = "no-cache";

    var res = await fetch(
      `${dbstore.endpoint}${path}?user=${dbstore.decodedUserId}`,
      options
    );
    res = await res.json();
    var conv = [];
    for (var i = 0; i < res.length; i++) {
      var obj = {
        convId: res[i]._id,
      };
      var index = 0;
      if (res[i].users[0].id == dbstore.decodedUserId) {
        index = 1;
      }
      obj.name = res[i].users[index].name;
      obj._id = res[i].users[index].id;
      obj.type = res[i].users[index].type;
      conv.push(obj);
    }
    console.log(conv, "conversations");
    this.conversations = conv;
    this.users = [];
    this.retrieved = true;

    Object.assign(this.users, conv);
  };

  getMessages = async () => {
    dbstore.checkSession();

    if (!this.convId) {
      return;
    }
    var msgs = [];
    var path = "/chat/messages";
    var options = {
      method: "get",

      credentials: "include",
      // headers: { "Content-Type": "application/json" },
    };
    options["cache"] = "no-cache";

    var res = await fetch(
      `${dbstore.endpoint}${path}?convId=${this.convId}&limit=${this.msgLimit}&skip=${this.msgSkip}`,
      options
    );
    res = await res.json();
    var m = res[0].messages;
    for (var i = 0; i < m.length; i++) {
      var obj = {
        content: m[i].msg,
        user: m[i].author == dbstore.decodedUserId ? 1 : 0,
        time: moment(m[i].timestamp).format("h:mm a"),
      };
      msgs.push(obj);
    }
    msgs = msgs.reverse();
    this.messages = [];
    console.log(res, "res message");
    Object.assign(this.messages, msgs);
    this.scrollToBottom();
  };

  listener = () => {
    console.log(dbstore.decodedUserId, "listener called");
    if (this.listening) {
      return;
    }
    if (dbstore.decodedUserId) {
      this.listening = true;
      return onSnapshot(
        doc(this.db, "listeners", dbstore.decodedUserId),
        (doc) => {
          console.log(
            "Current data: ",
            doc.data(),
            "\n dbstore.decodedUserId",
            dbstore.decodedUserId
          );
          var msg = doc.data();
          if (msg) {
            msg.user = 0;

            if (msg.convId == this.convId) {
              this.messages.push(msg);
              this.scrollToBottom();
            }
          }
        }
      );
    } else {
      return null;
    }
  };

  scrollToBottom = () => {};

  setSTB = (func) => {
    this.scrollToBottom = func;
  };

    //generic funciton to set firebase document
  statusListener = (id = null) => {
    console.log(dbstore.decodedUserId, "statusListener called");
    if (this.isStatusListenerListening) {
      return;
    }
    if (id) {
      this.isStatusListenerListening = true;
      return onSnapshot(
        doc(this.db, "status_listener", id),
        (doc) => {
          console.log("statusListener data: ", doc.data());
          let data = doc.data();
          if(data) {
              dbstore.isPharmacyOpen = data.isLoggedIn;
          }
        }
      );
    } else {
      return null;
    }
  };

  setFirebaseDoc = (db = this.db, collectionName, primaryKey, documentData) => {
    setDoc(doc(db, collectionName, primaryKey), documentData);
  };
}

const HelpStore = new Store;
export default HelpStore;