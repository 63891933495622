import { useEffect, useState } from "react";
import { Col, Row, Layout, Empty } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import RecentPatientItem from "./RecentPatientItem";
import { useHistory } from "react-router-dom";
import { themestore } from "../dataLayer/stores/ThemeStore";

const RecentPatients = observer(({ data, onClick = () => {} }) => {
  let history = useHistory();

  return (
    <Row style={{ padding: 20, minHeight: "30vh" }} className="drp-card">
      <Col span={24}>
        <Row onClick={() => history.push("patients")}>
          <Col span={12}>
            <h4
              className="drp-font-1"
              style={{ fontWeight: "400", color: themestore.colors.maroon }}
            >
              Recent Patients
            </h4>
          </Col>
          <Col span={12} style={{ textAlign: "end" }}>
            <SearchOutlined style={{ fontSize: "20px" }} />
          </Col>
        </Row>
      </Col>
      {data?.arr?.length > 0 ? (
        data.arr.map((item, index) => {
          return <RecentPatientItem data={item} onClick={onClick} />;
        })
      ) : (
        <Empty style={{ marginTop: "21%", marginLeft: "30%" }} />
      )}
    </Row>
  );
});
export default RecentPatients;
