import _ from "lodash";
import { makeAutoObservable } from "mobx";
import { dbstore } from "../dbStore";
import moment from "moment";
export default class Store {
  comp1 = {};
  comp2 = {};
  constructor() {
    makeAutoObservable(this);
  }

  get comp1Data() {
    if (dbstore.patientsList && dbstore.patientsList?.length > 0) {
      var obj = { arr: [] };
      dbstore.patientsList.forEach((item) => {
        var o = {
          pname: item.name,
          page: moment().diff(item.dob, "years", false),
          pgender: item.gender,
          isPillPackEnrolled: item.compliance?.pillPackEnrollment?.signed || false,
          lastVisited: moment().diff(parseInt(item.lastVisited), "days"),
          _id: item._id,
        };
        obj.arr.push(o);
      });
      console.log(obj);
      Object.assign(this.comp1, obj);
      return this.comp1;
    }
  }

  get comp2Data() {
    if (
      dbstore.patientsList &&
      dbstore.patientsList?.length > 0 &&
      dbstore.selectedPatientId
    ) {
      var obj = {
        ptinfo: {},
        ptrxlist: [],
      };

      var pti = _.find(dbstore.patientsList, {
        _id: dbstore.selectedPatientId,
      });

      if(!pti) {
        pti = dbstore.patientsList[0];
        dbstore.setSelectedPatientId(pti._id)
      }
      var rxarr = _.filter(dbstore.rxDetails, {
        patid: dbstore.selectedPatientId,
      });
      
      obj.ptinfo = {
        visitedDays: moment().diff(parseInt(pti.lastVisited), "days"),
        pname: pti.name,
        page: moment().diff(pti.dob, "years", false),
        pgender: pti.gender,
        alt: pti.name.substr(0, 2).toUpperCase(),
        isPillPackEnrolled: pti.compliance?.pillPackEnrollment?.signed || false,
      };

      rxarr.forEach((rx) => {
        var o = {
          id: rx._id,
          completedRefill: _.filter(rx.refills, { status: "completed" }).length,
          rxname: rx.name,
          status: rx.status,
          statusCode: rx.statuscode,
          transactionStatus: rx.transactionstatus,
          transactionStatusCode: rx.transactionstatuscode,
          date: moment(rx.lastUpdate).format("MM/DD/YYYY"),
          quantity: rx.quantity,
          doctorName: rx.docname,
          rxtype:
          rx.statuscode == 13
          ? "rr"
          : rx.transactionstatuscode == 2
          ? "pa"
          : "normal",
        };
        obj.ptrxlist.push(o);
      });

      Object.assign(this.comp2, obj);
      return this.comp2;
    }
  }
}

const patientsStore = new Store();
export { patientsStore };
