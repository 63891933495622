
import { Col, Row, Layout } from 'antd'
import { useEffect, useState } from 'react'
import ComplianceChartCard from "../ComplianceChartCard";
import ComplianceCard from "../ComplianceCard";
import PtDemographics from '../PtDemographics';
import { homeStore } from '../../dataLayer/stores/pages/homeStore';
import { observer } from 'mobx-react';
import { reportsStore } from '../../dataLayer/stores/pages/reportsStore';


var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const Reports = observer(({ props }) => {
  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <div style={{ ...box }}>
            <ComplianceChartCard
              // style={{ height: 350 }}
              chartHeight={75}
              data={
                reportsStore.comp1Data
              }
            />
          </div>
        </Col>
        <Col span={12}>
          <div style={box}>
            <ComplianceCard data={reportsStore.comp2Data} />
          </div>
        </Col>
        <Col span={12}>
          <div style={box}>
            <PtDemographics data={reportsStore.comp3Data} />
          </div>
        </Col>
      </Row>
    </>
  );
});
export default Reports;