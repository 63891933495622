
import _ from "lodash";
import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
} from "mobx";
import moment from "moment";
import { pauthActionStore } from "../actions/pauthActionStore";
import { dbstore } from "../dbStore";

export default class Store {

  comp1 = {}
  comp2 = {}
  constructor() {
    makeAutoObservable(this);
  }

  get comp1Data() {
    if (!dbstore.priorAuths) {
      return
    }
    var obj = { arr: [] };
    var arr = dbstore.priorAuths
    arr.forEach(item => {
      var o = {
        refills: item.refills.length, dname: item.name,
        pname: item.patname,
        id: item._id
      }
      obj.arr.push(o)
    });
    if (obj.arr.length > 0) {
      pauthActionStore.setSelected(obj.arr[0].id)
    }
    Object.assign(this.comp1, obj)
    return this.comp1
  }
  get comp2Data() {
    if (!pauthActionStore.comp1.selected) {
      return
    }
    if (!dbstore.priorAuths) {
      return
    }
    var obj = {};
    var rx = _.find(dbstore.priorAuths, { _id: pauthActionStore.comp1.selected })

    dbstore.setSelectedPatientId(rx.patid);
    obj = {
      rxname: rx.name, docname: dbstore.doctorDetails.name, rxstartdate: moment(rx.filldate).format('DD MMM YYYY'),
      rxstatus: rx.status,
      lastrefill: moment(rx.filldate).format('DD MMM YYYY'), rxenddate: moment(rx.expirydate).format('DD MMM YYYY'),
      refills: _.filter(rx.refills, { status: 'pending' }).length,
      rxquantity: rx.quantity, pname: rx.patname, page: 36, gender: rx.patgender, type: 'pa'
    }
    Object.assign(this.comp2, obj)
    console.log(JSON.stringify(obj), 'selected prior auth')

    return this.comp2
  }
}

const pauthStore = new Store();
export { pauthStore };

