import { inject, observer } from "mobx-react";
import { Card } from "react-bootstrap";
import { Colours } from "../Colours";
import ComplianceBubble from "./ComplianceBubble";
// import { store } from "../dataLayer/stores/store";

const ComplianceCard = observer(({ data }) => {
  console.log(data);
  return (
    <Card
      style={{
        width: "100%",
        marginTop: "10px",
        marginBottom: "10px",
        borderRadius: 30,
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
        minHeight: 240,
        padding: 15
      }}
    >
      <Card.Body>
        <Card.Title
          className={"text-center"}
          style={{
            fontFamily: "Nunito-Light",
            fontSize: 25,
            color: Colours.primaryText,
          }}
        >
          Your Patients Pill Compliance
        </Card.Title>
        <ComplianceBubble scale={0.5} showPercentage={true} percentage={data.percentage} />
      </Card.Body>
    </Card>
  );
});
export default ComplianceCard;
