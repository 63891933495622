import { Col, Row, Input, Select } from "antd";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { dbstore } from "../../dataLayer/stores/dbStore";
import { patientsStore } from "../../dataLayer/stores/pages/patientsStore";
import PtDetails from "../PtDetails";
import PtList from "../PtList";
import Triangle from "../Triangle";
import { useGetQueryStringParams } from "../../hooks/CustomHooks";

const { Option } = Select;

var box = {
  maxHeight: "80vh",
  backgroundColor: "transparent",
  margin: 10,
};
const Patients = observer(({ props }) => {
  const history = useHistory();
  const patientId = useGetQueryStringParams();

  useEffect(() => {

    // clearing filter and search values
    dbstore.setPatientFilter("pillPackEnrollment", "");
    dbstore.setPatientFilter("name", "");
    let isPatientSelected = patientId ? true : false;
    dbstore.getPatients(true, 0, true, isPatientSelected);
  }, []);

  const handleSelectFilter = (key) => {

    // if filter is changed re-setting the pagination back to 'ZERO'
    dbstore.setPatientsListQueryParams({skip: 0});

    var keys = ["pillPackEnrollment"];
    var val = "";
    switch (key) {
      case "pillPackEnrollment":
        val = true;
        break;
    }

    if (key == "none") {
      for (var i = 0; i < keys.length; i++) {
        dbstore.setPatientFilter(keys[i], "");
      }
    } else {
      dbstore.setPatientFilter(key, val);
    }
    dbstore.getPatients(true, 0, true);
  };

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={8}>
          <div
            style={{
              backgroundColor: "#fff",
              maxHeight: "95vh",
              marginTop: 15,
            }}
            className="drp-card"
          >
            <Row>
              <Col span={14}>
                <div
                  style={{
                    border: "1px solid #ddd",
                    borderRadius: 20,
                    padding: 0,
                  }}
                >
                  <Input
                    placeholder="Search Patient"
                    allowClear
                    bordered={false}
                    onChange={(e) => {
                      // REGEX TO VALIDATE THE STRING IF IT HAS ANY SPECIAL CHARECTERS
                      let isStringIncludesSpecialsCharacters = new RegExp(
                        /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
                      );
                      if (
                        !isStringIncludesSpecialsCharacters.test(e.target.value)
                      ) {
                        dbstore.setPatientFilter("name", e.target.value);
                        dbstore.getPatients(true, 0, true);
                      }
                    }}
                  />
                </div>
              </Col>
              <Col span={10} style={{ paddingLeft: 5 }}>
                <div
                  style={{
                    border: "1px solid #ddd",
                    borderRadius: 20,
                    padding: 0,
                  }}
                >
                  <Select
                    defaultValue="none"
                    style={{ width: "100%", margin: 0 }}
                    bordered={false}
                    onChange={(v) => {
                      console.log(v);
                      handleSelectFilter(v);
                    }}
                  >
                    <Option value="none">All</Option>
                    <Option value="pillPackEnrollment">
                      Pill Pack Enrolled
                    </Option>
                  </Select>
                </div>
              </Col>
            </Row>
            <PtList
              data={patientsStore.comp1Data}
              totalPatients={dbstore.totalPatients}
              onClick={(id) => {
                dbstore.setSelectedPatientId(id);
                history.push(`/patients?patientId=${id}`);
              }}
            />
          </div>
        </Col>
        <Col span={16}>
          <div style={box}>
            <PtDetails data={patientsStore.comp2Data} />
            <Triangle
              style={{
                position: "absolute",
                top: "25%",
                left: 0,
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
});
export default Patients;
