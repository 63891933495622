import { Col, Row, Layout } from "antd";
import { useEffect, useState } from "react";
import { profileStore } from "../../dataLayer/stores/pages/profileStore";
import ScrollToTopOnMount from "../nav/scrollToTopOnMount";
import PdfDisplay from "../PdfDisplay";
import SignAndApprove from "../SignAndApprove";
var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 1,
};
const Pdfview = ({ props }) => {
  return (

    <Row gutter={[32, 32]}>
      <ScrollToTopOnMount />
      {/* <Col span={18}>
        <div style={(box, { backgroundColor: "white", borderRadius: 30 })}>
          <PdfDisplay />
        </div>
      </Col> */}
      <Col span={24}>
        <div style={box}>
          <SignAndApprove data={{ signature: profileStore.comp1Data.signature }} />
        </div>
      </Col>
    </Row>

  );
};
export default Pdfview;
