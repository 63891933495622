
import { Col, Row, Layout } from 'antd'
import { useEffect, useState } from 'react'
import { observer } from "mobx-react";
import VitalInfoCard from './VitalInfoCard';

const VitalCardGroup = observer(({ data = null, onClick = () => { } }) => {

  return <>
    <Row justify="space-between">
      <VitalInfoCard vitalKey="bloodPressure" data={{ vitalsAverage: { bloodPressure: data?.bloodPressure } }} onClick={onClick} />
      <VitalInfoCard vitalKey="bloodGlucose" data={{ vitalsAverage: { bloodGlucose: data?.bloodGlucose } }} onClick={onClick} />
      <VitalInfoCard vitalKey="temperature" data={{ vitalsAverage: { temperature: data?.temperature } }} onClick={onClick} />
      <VitalInfoCard vitalKey="weight" data={{ vitalsAverage: { weight: data?.weight } }} onClick={onClick} />
      <VitalInfoCard vitalKey="oxygenLevels" data={{ vitalsAverage: { oxygenLevels: data?.oxygenLevels } }} onClick={onClick} />

    </Row>
  </>;
});
export default VitalCardGroup;
