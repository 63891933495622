import {
  Col,
  Row,
  Card,
  List,
  Typography,
  DatePicker,
  Button,
  Space,
} from "antd";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { dbstore } from "../../dataLayer/stores/dbStore";
import moment from "moment";

const { Text } = Typography;

const tabList = [
  {
    key: "daily_reports",
    tab: "Daily Reports",
  },
  {
    key: "time_logs",
    tab: "Time logs",
  },
];

const TechnicianReports = observer(({ props }) => {
  const [selectedTab, setSelectedTab] = useState("daily_reports");
  const [tabData, setTabData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    fetchData();
  }, [, selectedTab]);

  useEffect(() => {
    setData();
  }, [, dbstore.technicianReports, dbstore.technicianSessions]);

  function fetchData() {
    if (selectedTab === "daily_reports") {
      var to = moment().format("x");
      var from = moment().subtract(7, "days").format("x");
      dbstore.getTechnicianReports(true, from, to);
    } else if (selectedTab === "time_logs") {
      dbstore.getTechnicianSessions();
    }
  }

  function setData() {
    if (selectedTab === "daily_reports") {
      setTabData(dbstore.technicianReports);
    } else if (selectedTab === "time_logs") {
      setTabData(dbstore.technicianSessions);
    }
  }

  function onDateChange(date, dateString) {
    // Moment.js stores dates it utc and can apply different timezones to it. By default it applies your local timezone.
    // If you want to set time on utc date time you need to specify utc timezone
    let selectedDate = moment(new Date(date)).utcOffset(0);
    selectedDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    selectedDate.toISOString();
    selectedDate.format();
    setSelectedDate(date);
    dbstore.reportDate = selectedDate;
  }

  function renderListItem(data) {
    if (selectedTab === "daily_reports") {
      return (
        <>
          <List.Item>
            <Text>
              {" "}
              Daily Report for {moment(data.date).format("DD MMM yy, ddd")}{" "}
            </Text>
            <Text>
              {" "}
              {`${data.patientsVisited}${
                data.patientsVisited > 1 ? " Patients" : " Patient"
              } Served |
          ${data.prescriptionsFilled}${
                data.prescriptionsFilled > 1 ? "Prescriptions" : " Prescription"
              } Filled`}
            </Text>
          </List.Item>
        </>
      );
    } else if (selectedTab === "time_logs") {
      var checkin = moment(data.checkin, "x");
      var checkout = moment(data.checkout, "x");
      var dif = moment.duration(checkout.diff(checkin));
      let totalTime = `${Math.max(0, Math.floor(dif.asHours()))} hrs
        ${Math.max(0, ("0" + Math.floor(dif.asMinutes() % 60)).slice(-2))} min`;

      return (
        <List.Item>
          <Text>
            {`Clock in : ${moment(data.checkin).format(
              "DD MMM, yy"
            )} - Clock out : ${moment(data.checkout).format("DD MMM, yy")}`}
          </Text>
          <Text>{totalTime}</Text>
        </List.Item>
      );
    }
  }

  return (
    <>
      <Row gutter={[8, 8]}>
        <Card
          style={{
            width: "100%",
            marginTop: "10px",
            marginBottom: "10px",
            borderRadius: "30px",
            boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
            minHeight: "300px",
            maxHeight: "700px",
            overflow: "hidden",
          }}
          tabList={tabList}
          activeTabKey={selectedTab}
          onTabChange={(key) => setSelectedTab(key)}
          loading={dbstore.loading}
          hoverable
        >
          {selectedTab === "daily_reports" && (
            <Row>
              <Col span={24} style={{ textAlign: "end", paddingRight: 10 }}>
                <Space>
                  <DatePicker
                    style={{ minWidth: "220px", borderRadius: "1rem" }}
                    onChange={onDateChange}
                    value={selectedDate}
                    disabledDate= {(current) => current > moment().endOf('day')}
                  ></DatePicker>
                  <Button
                    className="drp-btn-green btn-small" 
                    size="small"
                    onClick={ () => dbstore.getTechnicianReports()}
                    disabled={!selectedDate}
                  >
                    Generate Report
                  </Button>
                </Space>
              </Col>
            </Row>
          )}
          <List
            style={{
              maxHeight: "700px",
              overflow: "auto",
              paddingRight: "10px",
              marginTop: "15px"
            }}
            dataSource={tabData}
            renderItem={(item) => renderListItem(item)}
          />
        </Card>
      </Row>
    </>
  );
});
export default TechnicianReports;
