import { Col, Row, message, Badge } from "antd";
import { observer } from "mobx-react";
import ContactCard from "../ContactCard";
import ProfilePersonalDetails from "../ProfilePersonalDetails";
import SettingsCard from "../SettingsCard";
import { profileStore } from "../../dataLayer/stores/pages/profileStore";
import { dbstore } from "../../dataLayer/stores/dbStore";
import HelpStore from "../../dataLayer/stores/pages/help";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const Profile = observer(({ props }) => {
  let history = useHistory();

  useEffect(() => {
    dbstore.getContactDetails(true);
  }, []);

  return (
    <>
      {!dbstore.loading && (
        <Row gutter={[8, 8]} style={{ width: "100%" }}>
          <Col span={10}>
            <div style={box}>
              <ProfilePersonalDetails
                data={profileStore.comp1Data}
                uploadProfilePic={async (file) => {
                  var res = await dbstore.newProfilePic(file);
                  if (res == 200) {
                    await dbstore.getdoctorDetails(true);
                    message.success("Profile Pic Successfully Changed");
                  } else {
                    message.error("Oops! something went wrong");
                  }
                }}
              />
            </div>
          </Col>
          <Col span={14}>
            <div style={box}>
              <Badge.Ribbon
                text={`Pharmacy ${dbstore.isPharmacyOpen ? `Open` : `Closed`}`}
                color={dbstore.isPharmacyOpen ? "green" : "red"}
              >
                <ContactCard
                  data={{
                    name: dbstore.contacts["lead_technician"].name,
                    phone: dbstore.contacts["lead_technician"].phone,
                    type: dbstore.contacts["lead_technician"].role,
                    age: "",
                    gender: "",
                  }}
                  // onChat={async () => {
                  //   await HelpStore.setCurrentUserName(
                  //     dbstore.contacts["technician"].name
                  //   );
                  //   await HelpStore.setCurrentUser(
                  //     dbstore.contacts["technician"]._id
                  //   );
                  //   await HelpStore.setCurrentUserType("technician");
                  //   history.push("/help");
                  // }}
                />
              </Badge.Ribbon>
            </div>
            <div style={{ ...box, marginTop: 25 }}>
              <ContactCard
                data={{
                  name: dbstore.contacts["regional_manager"].name,
                  phone: dbstore.contacts["regional_manager"].phone,
                  type: dbstore.contacts["regional_manager"].role,
                  age: "",
                  gender: "",
                }}
                // onChat={async () => {
                //   await HelpStore.setCurrentUserName(
                //     dbstore.contacts["admin"].name
                //   );
                //   await HelpStore.setCurrentUser(dbstore.contacts["admin"]._id);
                //   await HelpStore.setCurrentUserType("admin");
                //   history.push("/help");
                // }}
              />
            </div>
          </Col>
          <Col span={8}>
            <div style={box}>
              <SettingsCard
                submit={async (key, value) => {
                  var res = await dbstore.updateSettings(key, value);
                  if (res == 200) {
                    message.success("Settings Updated Successfully");
                  } else {
                    message.error("Oops! some thing went wrong, try again");
                  }
                }}
                data={profileStore.comp4Data}
              />
            </div>
          </Col>
        </Row>
      )}
    </>
  );
});
export default Profile;
