import {
  makeAutoObservable,
} from "mobx";
import { dbstore } from "../dbStore";
import { homeActionStore } from "../actions/homeActionStore";
import _ from "lodash";
import moment from "moment";

export default class Store {
  comp1 = {};
  comp2 = {};
  comp3 = {};
  comp4 = {};
  comp5 = {};
  comp6 = {};
  comp7 = {};
  constructor() {
    makeAutoObservable(this);
  }

  get comp1Data() {
    Object.assign(this.comp1, { percentage: dbstore?.doctorPillCompliancePercentage || 0 });
    return this.comp1;
  }

  get comp2Data() {
    var period = 30;
    var datalist = [
      {
        date: "1622678400000",
        docid: "60ab4e837c0d2b4b74a9c979",
        missed: 3,
        patid: "60ab4e7a574c694b60994106",
        phid: "60aaaeb9cb311c3955146896",
        rxid: "60ac9bf4d06b017e5df767c3",
        taken: 2,
        total: 9,
        unanswered: 4,
      },
    ];
    if (dbstore.pcarrDaily) {
      period = homeActionStore.comp2.period;
      datalist = [];
      for (var i = 0; i < period; i++) {
        if (i < dbstore.pcarrDaily.length) {
          datalist.push(dbstore.pcarrDaily[i]);
        }
      }
      // datalist = this.dbstore?.pcarrDaily;
      // datalist = []
      // console.log(datalist)
    }
    Object.assign(this.comp2, { graphData: datalist, timePeriod: period });
    return this.comp2;
  }
  get comp3Data() {
    if (!dbstore.rxDetails) {
      return;
    }
    this.comp3 = _.filter(dbstore.rxDetails, { statuscode: 13 }).length;
    return this.comp3;
  }
  get comp4Data() {
    if (!dbstore.priorAuths) {
      return;
    }
    this.comp4 = dbstore.priorAuths.length;
    return this.comp4;
  }
  get comp5Data() {
    return this.comp5;
  }
  get comp7Data() {
    if (!dbstore.rxDetails) {
      return;
    }
    var obj = { arr: [] };
      var arr = dbstore.rxDetails.slice(0, 3);
    arr.forEach((item) => {
      var o = {
        date: moment(item.lastUpdate).format("DD MMM YY"),
        time: moment(item.lastUpdate).format("hh:mm a"),
        name: item.name,
        directions: item.directions,
        id: item._id,
        patid: item.patid,
      };
      obj.arr.push(o);
    });

    Object.assign(this.comp7, obj);
    return this.comp7;
  }

  get comp6Data() {
    if (!dbstore.rxDetails) {
      return;
    }
    if (dbstore.recentPatients && dbstore.recentPatients?.length > 0) {
      var obj = { arr: [] };

      var ptarr = dbstore.recentPatients.slice(0, 3);;

      ptarr.forEach((item, index) => {
        var o = {
          date: moment(parseInt(item.lastVisited)).format("DD MMM YY"),
          time: moment(parseInt(item.lastVisited)).format("hh:mm a"),
          name: item?.name || "",
          id: item._id,
          avatarText: item.name.substr(0, 2).toUpperCase(),
          age: moment().diff(item.dob, 'years', false),
          gender: item.gender,
        };
        obj.arr.push(o);
      });

      Object.assign(this.comp6, obj);
      return this.comp6;
    }
  }
}

const homeStore = new Store();
export { homeStore };
