const getSin = (p) => {
  let rad = 2 * (p / 100) * Math.PI;
  return Math.sin(rad);
};
const getCos = (p) => {
  let rad = (2 * p * Math.PI) / 100;
  return Math.cos(rad);
};
const getCoords = (p, r, size) => {
  let center = size * 0.5;
  if (p === 100) {
    return `1 1 ${Math.floor(center - 1)} ${center - r}`;
  }
  return `${p > 50 ? 1 : 0} 1 ${center + r * getSin(p)} ${
    center - r * getCos(p)
  }`;
};
const SVGCircleProgressIndicator = ({
  size = 100,
  color,
  percentage,
  strokeWidth = 7,
}) => {
  const radius = size * 0.4;
  const center = size * 0.5;
  return (
    <svg height={size} width={size}>
      <circle
        cx={center}
        cy={center}
        r={radius}
        fill="transparent"
        stroke={"#E0E4EB"}
        strokeWidth={strokeWidth}
      />
      <path
        d={`M ${center} ${center - radius} A ${radius} ${radius} 0 ${getCoords(
          percentage,
          radius,
          size
        )}`}
        fill="none"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <text
        fill="#140F26"
        stroke="#140F26"
        fontSize="18"
        fontFamily="Montserrat-SemiBold"
        x={center}
        y={center + 8}
        textAnchor="middle"
      >
        {`${percentage}%`}
      </text>
    </svg>
  );
};
export default SVGCircleProgressIndicator;
