import { inject, observer } from "mobx-react";
import ComplianceBubble from "./ComplianceBubble";
import { Row } from 'antd'
import StatusIndicatorDot from "./StatusIndicatorDot";

const BubbleStatusCard = (
  observer(({ percentage, marginLeft }) => {
    return (
      <Row

        align="bottom"
        justify="center"
        style={{ borderRadius: 10, backgroundColor: '#fff', height: '100%', padding: 0, paddingBottom: 20, boxShadow: 'none' }}
      >
        <ComplianceBubble
          marginLeft={marginLeft}
          compScale={.2}
          showPercentage={percentage ? true : false}
          percentage={percentage}
        />
        {/* <StatusIndicatorDot
          style={{ position: "absolute", top: 10, right: 10 }}
        /> */}
      </Row>
    );
  })
);
export default BubbleStatusCard;
