
import { Col, Row, Layout, Empty } from 'antd'
import { useEffect, useState } from 'react'
import { observer } from "mobx-react";
const RecentPatientItem = observer(({ data, onClick = () => { } }) => {

  return data ?
  <svg width={"auto"} style={{ marginBottom: 1 }} height={76} viewBox="0 0 616.261 76">
    <defs>
      <style>
        {
          ".a{fill:#f0f0f7;}.b,.d,.e{fill:#646d82;}.b,.d{font-size:18px;}.b{font-size:14px, font-family:SegoeUI-Semibold, Segoe UI;font-weight:600;}.c{fill:#272d5c;font-size:18px;}.c,.e{font-family:Nunito-Regular, Nunito;}.d{font-family:SegoeUI, Segoe UI;}.e{font-size:16px;}.f{fill:url(#a);}.g{fill:#fff;font-size:9px;font-family:Montserrat-Medium, Montserrat;font-weight:500;letter-spacing:0.091em;}"
        }
      </style>
      <linearGradient
        id="a"
        x1={0.125}
        y1={-0.865}
        x2={0.718}
        y2={1.268}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#2dc9eb" />
        <stop offset={1} stopColor="#14d2b8" />
      </linearGradient>
    </defs>
    <path
      className="a"
      d="M3.374,0H612.887a3.2,3.2,0,0,1,3.374,3V73a3.2,3.2,0,0,1-3.374,3H3.374A3.2,3.2,0,0,1,0,73V3A3.2,3.2,0,0,1,3.374,0Z"
    />
    <text className="b" transform="translate(27 54)">
      <tspan x={0} y={0}>
        {data.date}
      </tspan>
    </text>
    <text className="b" transform="translate(27 34)">
      <tspan x={0} y={0}>
        {data.time}
      </tspan>
    </text>
    <text className="c" transform="translate(216 34)">
      <tspan x={0} y={0}>
        {data.name}
      </tspan>
    </text>
    <text className="d" transform="translate(214 54)">
      <tspan x={0} y={0}>
        {`${data.age}, ${data.gender}`}
      </tspan>
    </text>
    <text className="e" transform="translate(597 54)" style={{ cursor: 'pointer' }} onClick={() => { onClick(data.id) }}>
      <tspan x={-75.894} y={0}>
        {"View more>"}
      </tspan>
    </text>
    <circle
      className="f"
      cx={23.5}
      cy={23.5}
      r={23.5}
      transform="translate(141.208 17.567)"
    />
    <text className="g" transform="translate(163.708 44.5)">
      <tspan x={-7.11} y={0} style={{ fontWeight: 'bold', fontSize: 12, fontFamily: "Nunito-regular" }}>
        {data.avatarText}
      </tspan>
    </text>
  </svg>
  :
  <Empty />
});
export default RecentPatientItem;
