
import {
    makeAutoObservable,
    action,
} from "mobx";
import Cookies from 'js-cookie'
import jwt_decode from "jwt-decode";
import moment from 'moment';
import _ from "lodash";

const fetch = require("node-fetch");

//const endpoint = "https://api.doctorspharmacy.us";
const endpoint = process.env.REACT_APP_SERVER_URL
export default class dbStore {
    endpoint = endpoint;
    loading = true;
    login = false;
    userid = null;
    selectedPatientId = null;
    patientReports = null;
    patientVitalRecords = null;
    doctorReports = null;
    doctorDetails = null;
    recentPatients = null;
    rxDetails = null;
    pcarrDaily = null;
    globalRefresh = false;
    refillRequests = [];
    priorAuths = null;
    contacts = {};
    decodedUserId = null;
    reportDate = moment().startOf('day').format('x');
    technicianReports = [];
    technicianSessions =  [];
    patientsList = null;
    totalPatients = 0;
    patientsListTotalPages = 0;
    patientsListQueryParams = {
        limit: 10,
        skip: 0,
        filterPath: ""
    };
    isPharmacyOpen = false;
    doctorPillCompliancePercentage = 0;
    patientsComplianceArray = [];
    assignedTechnician = {};

    constructor() {
        makeAutoObservable(this, {
            setSelectedPatientId: action
        });
    }

    *getData(refresh = false) {
        console.log(this.globalRefresh)
        this.refreshStore()
        if (!this.login) {
            return
        }
        this.globalRefresh = true;
        this.loading = true;
        yield this.getContactDetails(refresh)
        yield this.getAssignedTechnician(refresh)
        yield this.getdoctorDetails(refresh)
        yield this.getRecentPatients(refresh)
        yield this.getrxDetails(refresh)
        yield this.getdoctorReports(refresh)
        yield this.getReportStats(refresh);
        yield this.getDailyPc(refresh);

        this.loading = false;
        this.globalRefresh = false;
    }
    *getReportStats(refresh = false) {
        this.checkSession()
        var path = '/doctors/reportstats';
        var options = {
            method: "get",
            
            credentials: 'include'
            // headers: { "Content-Type": "application/json" },
        }
        if (refresh) {
            this.loading = true;
            options['cache'] = "no-cache"
        }
        var res = yield fetch(
            // `${endpoint}${path}?docid=6171d401e7fcc85cb85384f2`,
            `${endpoint}${path}?docid=${this.decodedUserId}`,
            options,

        );
        res = yield res.json();
        console.log('report stats -------------------------------------------------', res);
        // this.contacts = res
        // this.refillsDue = res.refillsDueNextWeek
        this.refillRequests = res.refillRequests
        this.priorAuths = res.priorAuth
        this.loading = false;
    }
    *updateSettings(key, value) {
        this.checkSession()
        this.loading = true;

        let keyToBeUpdated = `settings.${key}`;
        var raw = {
            condition: { "_id": this.userid },
            data: { [keyToBeUpdated]: value }
        }

        console.log(raw)
        var res = yield fetch(
            `${endpoint}/doctors`,
            {
                method: "put",

                headers: { "Content-Type": "application/json" },
                credentials: 'include',
                body: JSON.stringify(raw)

            }
        );

        var status = res.status;
        res = yield res.json();
        console.log(res);
        yield this.getdoctorDetails(true);
        this.loading = false;
        return status
    }
    *updateReportStats(rxno, data) {
        console.log("Update reprt stats ", rxno, JSON.stringify(data));
        this.checkSession()
        var path = `/doctors/reportstats/`;
        var options = {
            method: "put",
            
            credentials: 'include',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        }
        var res = yield fetch(
            `${endpoint}${path}?rxno=${rxno}`,
            options,
        );

        if(res.status == 200) {
            res = yield res.json();
            this.getReportStats();
        }
        console.log('update report stats -------------------------------------------------', res);

    }

    *getContactDetails(refresh = false) {
        this.checkSession()

        this.loading = true
        var path = '/doctors/contacts';
        var options = {
            method: "get",
            credentials: 'include'
        }
        if (refresh) {
            options['cache'] = "no-cache"
        }
        var res = yield fetch(
            `${endpoint}${path}?docid=${this.decodedUserId}`,
            options,

        );
        res = yield res.json();
        console.log(res, 'contacts -------------------------------------------------');
        this.contacts = res
        this.loading = false;
    }
    *newSigPic(file) {
        this.loading = true;
        var raw = { userid: this.userid }
        var res = yield fetch(`${endpoint}/doctors/signatureUpload`, {
            method: "post",
            credentials: 'include',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(raw)
        });
        var status = res.status;
        res = yield res.json();
        console.log(res)
        if (status == 200) {
            var url = res.url

            var s = yield this.uploadFile(url, file);
            yield this.getdoctorDetails(true);
            // this.doctorDetails = {
            //     ...this.doctorDetails,
            //     signature: url
            // }
            console.log(s)
            status = s
        }
        this.loading = false;
        return status;
    }
    *newProfilePic(file) {
        this.checkSession()

        this.loading = true;
        var path = '/doctors/profilepic';
        var raw = { userid: this.userid }
        var res = yield fetch(
            `${endpoint}${path}`,
            {
                method: "post",
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(raw)
            },

        );
        var status = res.status;
        res = yield res.json();
        console.log(res);
        if (status == 200) {
            var url = res.url

            var s = yield this.uploadFile(url, file)

            console.log(s)
            status = s
        }

        this.loading = false;
        return status;
    }

    *newPassword(obj) {
        this.checkSession()

        this.loading = true;
        var path = '/doctors/newpass';
        var raw = {
            ...obj
        }
        var res = yield fetch(
            `${endpoint}${path}`,
            {
                method: "post",
                // cache: "no-cache",
                credentials: 'include',
                // headers: { "Content-Type": "application/json" },
                body: JSON.stringify(raw)
            },

        );
        var status = res.status;
        // res = yield res.json();
        this.loading = false;
        return status;
    }

    *newPin(obj) {
        this.checkSession()

        this.loading = true;
        var path = '/doctors/newpin';
        var raw = {
            ...obj
        }
        var res = yield fetch(
            `${endpoint}${path}`,
            {
                method: "post",
                // cache: "no-cache",
                credentials: 'include',
                // headers: { "Content-Type": "application/json" },
                body: JSON.stringify(raw)
            },

        );
        var status = res.status;
        // res = yield res.json();
        this.loading = false;
        return status;
    }

    *signRR(obj) {
        this.checkSession()

        this.loading = true;
        var path = '/doctors/signRR';
        var raw = {
            ...obj
        }
        var res = yield fetch(
            `${endpoint}${path}`,
            {
                method: "post",
                // cache: "no-cache",
                credentials: 'include',
                // headers: { "Content-Type": "application/json" },
                body: JSON.stringify(raw)
            },

        );
        var status = res.status;
        // res = yield res.json();
        this.loading = false;
        return status;
    }

    *getpatientReports(patid = null, refresh = false) {
        this.checkSession()

        console.log(refresh);
        patid = patid ? patid : this.selectedPatientId
        this.loading = true;
        var path = '/doctors/patients/reports';
        
        var res = yield fetch(
            `${endpoint}${path}?patid=${patid}`,
            {
                method: "get",
                cache: "no-cache",
                credentials: 'include'
            },
            );
            res = yield res.json();
        this.patientReports = res[0];
        this.loading = false;
    }


    *getpatientVitalRecords(type, limit, page, refresh = false) {
        this.checkSession()

        console.log(refresh)
        this.patientVitalRecords = null
        this.loading = true;
        var path = '/doctors/vitalrecords';
        var options = {
            method: "get",
            // cache: "no-cache",
            credentials: 'include'
            // headers: { "Content-Type": "application/json" },
        }
        var res = yield fetch(
            `${endpoint}${path}?patid=${this.selectedPatientId}&type=${type}&limit=${limit}&page=${page}`,
            options

        );
        res = yield res.json();
        console.log(res);
        this.patientVitalRecords = res;
        this.loading = false;
    }


    *getdoctorReports(refresh = false) {
        this.checkSession()

        console.log(refresh)
        var path = '/doctors/reports';
        var options = {
            method: "get",
            // cache: "no-cache",
            credentials: 'include'
            // headers: { "Content-Type": "application/json" },
        }
        if (refresh) {
            this.loading = true;
            options['cache'] = "no-cache"
        }
        var res = yield fetch(
            `${endpoint}${path}?_id=${this.userid}`,
            options

        );
        res = yield res.json();
        // console.log(res, 'doctor reports');
        this.doctorReports = res[0];
        this.loading = false;
    }


    *getdoctorDetails(refresh = false) {
        this.checkSession()

        console.log(refresh)
        // this.loading = true;
        var path = '/doctors/details';
        var options = {
            method: "get",
            // cache: "no-cache",
            credentials: 'include'
            // headers: { "Content-Type": "application/json" },
        }
        if (refresh) {
            options['cache'] = "no-cache"
        }
        var res = yield fetch(
            `${endpoint}${path}?_id=${this.userid}`,
            options

        );
        res = yield res.json();
        console.log(res);
        this.doctorDetails = res[0];
        // this.loading = false;
    }
    patientFilter = {};

    setPatientFilter(key, val) {
        if (val != '') {
            this.patientFilter[key] = val
        } else {
            delete this.patientFilter[key]
        }
    }

    setPatientsListQueryParams(data) {
        let obj = {...this.patientsListQueryParams, ...data};
        Object.assign(this.patientsListQueryParams, obj);
    }

    *getRecentPatients(refresh) {
        let patients = yield this.getpatientDetails(refresh);
        this.recentPatients = patients;
        this.loading = false;
    }

    *getPatients(refresh = false, skip = this.patientsListQueryParams.skip, newFilter = false) {

        let limit = this.patientsListQueryParams.limit;
        this.patientsListQueryParams.skip = skip;
        let skipLimit = skip * limit;
        let filterPath = '';

        for (var key in this.patientFilter) {
            if (this.patientFilter[key] != '') {
                filterPath += '&' + key + '=' + this.patientFilter[key];
                this.patientsListQueryParams.filterPath = filterPath;
            }
        }
        let patients = yield this.getpatientDetails(refresh, skipLimit, limit, filterPath);
        if (patients.length > 0) {
            if (this.patientsList) {
                if (newFilter) {
                    this.patientsList = patients;
                    if(!this.selectedPatientId) {
                        this.setSelectedPatientId(patients[0]._id);
                    }
                } else {
                    this.patientsList.push(...patients);
                }
            } else {
                this.patientsList = patients;
                if(!this.selectedPatientId) {
                    this.setSelectedPatientId(patients[0]._id);
                }
            }
        }
        this.loading = false;
        return patients;
    }

    *getpatientDetails(refresh = false, skip = this.patientsListQueryParams.skip, limit = this.patientsListQueryParams.limit, filterPath = this.patientsListQueryParams.filterPath) {
        this.checkSession();

        console.log(refresh);
        
        var path = '/doctors/patients';
        var options = {
            method: "get",
            credentials: 'include'
        }
        if (refresh) {
            options['cache'] = "no-cache"
        }
        var res = yield fetch(
            `${endpoint}${path}?docid=${this.userid}&limit=${limit}&skip=${skip}${filterPath}`,
            options
        );
        res = yield res.json();
        this.patientsListTotalPages = res.totalPages;
        this.totalPatients = res.totalPatients;
        res = res.patients;
        this.loading = false;
        return res;
    }


    *getrxDetails(refresh = false) {
        this.checkSession();

        console.log(refresh)
        var path = '/doctors/rx';
        var options = {
            method: "get",
            cache: "no-cache",
            credentials: 'include'
        }
        var res = yield fetch(
            `${endpoint}${path}?docid=${this.userid}`,
            options

        );
        res = yield res.json();
        res = _.sortBy(res, ['lastUpdate']).reverse();
        this.rxDetails = res;
    }

    *getDailyPc(refresh = false) {
        this.checkSession()

        console.log(refresh)
        // this.loading = true;
        var path = '/doctors/pillcompliance/daily';
        var options = {
            method: "get",
            // cache: "no-cache",
            credentials: 'include'
            // headers: { "Content-Type": "application/json" },
        }
        if (refresh) {
            options['cache'] = "no-cache"
        }
        var res = yield fetch(
            `${endpoint}${path}?docid=${this.userid}`,
            options

        );
        res = yield res.json();
        console.log(res);
        this.pcarrDaily = res;
        // this.loading = false;
    }



    *uploadFile(url, file) {
        this.checkSession()

        this.loading = true;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "image/jpeg");
        var res = yield fetch(url, {
            method: "PUT",
            body: file,
            headers: myHeaders,
            redirect: "follow",
        })

        var status = res.status;
        this.loading = false;

        return status;
    }

    *loginFunc(phone, otp) {

        var raw = {
            phone: phone,
            otp: otp
        }
        var res = yield fetch(
            `${endpoint}/auth/doctors/login`,
            {
                method: "post",
                headers: { "Content-Type": "application/json" },
                credentials: 'include',
                body: JSON.stringify(raw)
            }
        );

        var status = res.status;
        res = yield res.json();
        if (status == 200) {
            this.login = true;
            this.globalRefresh = true;
            this.userid = res._id;
            yield this.getData(true)

        }
        return status;
    }

    *requestOTP(phone) {

        var raw = {
            phone: phone
        }
        var res = yield fetch(
            `${endpoint}/auth/doctors/otp`,
            {
                method: "post",
                headers: { "Content-Type": "application/json" },
                credentials: 'include',
                body: JSON.stringify(raw)
            }
        );

        var status = res.status;
        res = yield res.json();
        console.log(res, status);
        if (status == 200) {
            this.loading = false;
        }
        return status;
    }

    *logoutFunc() {
        this.loading = true;

        var res = yield fetch(
            `${endpoint}/auth/doctors/logout`,
            {
                method: "post",
                headers: { "Content-Type": "application/json" },
                credentials: 'include',

            }
        );

        var status = res.status;
        res = yield res.json();
        // console.log(res, status);
        this.loading = false;
        // if (status == 200) {

        //     this.login = false;
        //     this.resetStore();

        // }
        return status;
    }

    *updateSettings(key, value) {
        this.checkSession()
        this.loading = true;

        let keyToBeUpdated = `settings.${key}`;
        var raw = {
            condition: { "_id": this.userid },
            data: { [keyToBeUpdated]: value }
        }

        console.log(raw)
        var res = yield fetch(
            `${endpoint}/doctors`,
            {
                method: "put",

                headers: { "Content-Type": "application/json" },
                credentials: 'include',
                body: JSON.stringify(raw)

            }
        );

        var status = res.status;
        res = yield res.json();
        console.log(res);
        yield this.getdoctorDetails(true);
        this.loading = false;
        return status
    }


    decodedUserId = null
    refreshStore() {
        this.login = Cookies.get('dlogin') == 'true'
        this.decodedUserId = Cookies.get('dtoken') ? jwt_decode(Cookies.get('dtoken'))._id : null;
        this.userid = this.decodedUserId;
    }
    resetStore() {
        console.log("reset store called:::::::::");
        this.loading = true;
        this.login = false;
        this.userid = null;
        this.selectedPatientId = null;
        this.patientReports = null;
        this.patientVitalRecords = null;
        this.doctorReports = null;
        this.doctorDetails = null;
        this.recentPatients = null;
        this.rxDetails = null;
        this.pcarrDaily = null;
        this.globalRefresh = false;
        this.decodedUserId=null;
        this.doctorPillCompliancePercentage = 0;
        this.patientsComplianceArray = [];
    }
    setSelectedPatientId(id) {
        this.selectedPatientId = id;
    }

    checkSession() {
        this.login = Cookies.get('dlogin') == 'true'
        if(this.login === false) {
            this.resetStore();
          }
    }

    *getTechnicianReports(refresh = false, fromDate, toDate) {

        this.loading = true;
        var path = '/doctors/technicianreports';
 
        let from = fromDate ? moment(Number(fromDate)).utc().startOf('day') : moment(Number(this.reportDate)).utc().startOf('day');
        let to = toDate ? moment(Number(toDate)).utc().startOf('day') : moment(Number(this.reportDate)).utc().endOf('day');

        console.log(from, to, this.reportDate, 'from to');
        var options = {
            method: "get",
            cache: "no-cache",
            credentials: 'include'
        }

        var res = yield fetch(
            `${endpoint}${path}?docid=${this.decodedUserId}&from=${from.toISOString()}&to=${to.toISOString()}`,
            options,

        );
        res = yield res.json();
        var arr = []
        for (var i = 0; i < res.length; i++) {
            var keys = Object.keys(res[i])
            var key = keys[0]
            arr.push({
                date: key,
                patientsVisited: res[i][key].patientsVisited,
                prescriptionsFilled: res[i][key].prescriptionsFilled
            })
        }
        // sorting the reports by latest 
        arr.sort((a,b) => {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.date) - new Date(a.date);
          });
        this.technicianReports = arr;
        console.log('technician reports -------------------------------------------------', JSON.stringify(arr));

        this.loading = false;
        return this.technicianReports;
    }

    *getTechnicianSessions(refresh = false) {

        this.loading = true
        var path = '/doctors/technicians/sessions';

        var options = {
            method: "get",
            credentials: 'include',
            cache: 'no-cache'
        }

        var res = yield fetch(
            `${endpoint}${path}?docid=${this.decodedUserId}`,
            options,
        );
        res = yield res.json();
        this.technicianSessions = res;
        this.loading = false;

        return this.technicianSessions;
    }

    *getAssignedTechnician(refresh = false) {
        this.checkSession()

        this.loading = true
        var path = '/doctors/assigned/technician';
        var options = {
            method: "get",
            credentials: 'include'
        }
        if (refresh) {
            options['cache'] = "no-cache"
        }
        var res = yield fetch(
            `${endpoint}${path}?docid=${this.decodedUserId}`,
            options,
        );
        if(res.status == 200) {

            res = yield res.json();
            console.log('getAssignedTechnician -------------------------------------------------', res);
            this.assignedTechnician = res;
        }
        this.loading = false;
    }

    *getDoctorPillCompliance() {
        let clientDate = moment().utc(true).format()

        let pillComplianceRes = yield fetch(
            `${endpoint}/doctors/${this.userid}/date/${clientDate}/activepillbox/compliance`,
            {
              method: "get",
              credentials: 'include',
              headers: { "Content-Type": "application/json" },
              cache: 'no-cache'
            }
          );

          if(pillComplianceRes.status == 200) {
                pillComplianceRes = yield pillComplianceRes.json();

                this.doctorPillCompliancePercentage = pillComplianceRes?.compliancePercentage || 0;
                this.patientsComplianceArray = pillComplianceRes?.patientsComplianceData || [];

                let cookieAttributes = {
                    path: '/',
                    domain: '.doctorspharmacy.us',
                    };
        
                Cookies.set('doctorComplianceLastFetchedOn', clientDate, cookieAttributes);
            }
    }

}
const dbstore = new dbStore();
export { dbstore };

