import { Col, Row, Layout, Empty } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import SVGCircleProgressIndicator from "./SVGCircleProgressIndicator";
const PtDemographics = observer(({ data }) => {
  return (
    <>
      {data ? (
        <Row className="mx-0 justify-content-between drp-card">
          <Col className="d-flex flex-column align-items-center">
            <SVGCircleProgressIndicator
              size={120}
              color="#6E5EFF"
              percentage={data.wellCompliant}
            />
            <div
              style={{
                fontSize: 13,
                fontFamily: "Nunito-Regular",
                color: "#131315",
                textAlign: "center",
              }}
            >
              Well Compliant
            </div>
            <div
              style={{
                fontSize: 11,
                fontFamily: "Nunito-Regular",
                color: "#6C7B8A",
                textAlign: "center",
              }}
            >
              Patients
            </div>
          </Col>
          <Col className="d-flex flex-column align-items-center">
            <SVGCircleProgressIndicator
              size={120}
              color="#14D2B8"
              percentage={data.mediumCompliant}
            />
            <div
              style={{
                fontSize: 13,
                fontFamily: "Nunito-Regular",
                color: "#131315",
                textAlign: "center",
              }}
            >
              Medium Compliant
            </div>
            <div
              style={{
                fontSize: 11,
                fontFamily: "Nunito-Regular",
                color: "#6C7B8A",
                textAlign: "center",
              }}
            >
              Patients
            </div>
          </Col>
          <Col className="d-flex flex-column align-items-center">
            <SVGCircleProgressIndicator
              size={120}
              color="#FF8B8C"
              percentage={data.lowCompliant}
            />
            <div
              style={{
                fontSize: 13,
                fontFamily: "Nunito-Regular",
                color: "#131315",
                textAlign: "center",
              }}
            >
              Low Compliant
            </div>
            <div
              style={{
                fontSize: 11,
                fontFamily: "Nunito-Regular",
                color: "#6C7B8A",
                textAlign: "center",
              }}
            >
              Patients
            </div>
          </Col>
        </Row>
      ) : (
        <Row className="mx-0 justify-content-between drp-card">
          <Empty
            style={{ marginLeft: "30%" }}
            description="Compliance data not available"
          />
        </Row>
      )}
    </>
  );
});
export default PtDemographics;
