import { Menu, Affix } from "antd";
import { useEffect, useState } from "react";
import { dbstore } from "../dataLayer/stores/dbStore";
import { useHistory } from "react-router-dom";

const ProfileMenu = () => {
  const history = useHistory();

  async function handleLogout() {
        let status = await dbstore.logoutFunc();
        if (status == 200) {
          dbstore.login = false;
          dbstore.resetStore();
        }
  }

  return (
    <Affix offsetTop={60}>
      <Menu>
        <Menu.Item
          key="0"
          onClick={handleLogout}
        >
          Logout
        </Menu.Item>
      </Menu>
    </Affix>
  );
};
export default ProfileMenu;
