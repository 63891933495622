import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { dbstore } from "../dataLayer/stores/dbStore";

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export function ShowWindowDimensions(props) {
  const [width, height] = useWindowSize();
  return (
    <span>
      Window size: {width} x {height}
    </span>
  );
}


export function useGetQueryStringParams() {
  const { search } = useLocation();

  const [paramValue, setParamValue] = useState(null);

  useEffect(() => {
    
    const query = new URLSearchParams(search);
    const value = query.get('patientId');
    if(value) {
      setParamValue(value);
      dbstore.setSelectedPatientId(value);
    }
  }, [])
  
  return paramValue;
}