import React, { useRef } from "react";
import { inject, observer } from "mobx-react";
import ProfilePic from "./ProfilePic";
import { Colours } from "../Colours";
const ProfileChip = observer(
  ({
    store,
    name,
    phone,
    supportingText = '',
    style,
    scale = 1,
    allowEdit,
    entity,
    avatar,
    onEdit = () => { }
  }) => {
    const image = useRef();
    
    const styles = {
      container: {
        marginVertical: 10,
        backgroundColor: Colours.primaryBg,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        alignSelf: "stretch",
        flexWrap: "wrap",
      },
      avatarContainer: {
        height: 100 * scale,
        width: 100 * scale,
        borderRadius: 50 * scale,
        backgroundColor: "#f1f1f1",
        marginRight: 10,
        marginTop: 10,
        marginBottom: 10,
        flexWrap: "wrap",
        justifyContent: "flex-end",
        overflow: "hidden",
      },
      picture: { height: 100 * scale, width: 100 * scale },
      editContainer: {
        position: "absolute",
        bottom: 15,
        backgroundColor: "#ffffff40",
        height: 40 * scale,
        width: 100 * scale,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      },
      edit: {
        fontSize: 10 * scale,
        fontFamily: "Nunito-SemiBold",
        color: "#aaa",
        textTransform: "uppercase",
      },
      avatarName: {
        fontSize: 34 * scale,
        fontFamily: "Nunito-Regular",
        color: Colours.primaryText,
      },
      avatarPhone: {
        fontSize: 18 * scale,
        fontFamily: "Nunito-Regular",
        color: Colours.primaryText,
      },
      avatarSupportingText: {
        fontSize: 24 * scale,
        fontFamily: "Nunito-Regular",
        color: Colours.primaryText,
      },
    };
    return (
      <div style={{ ...styles.container, ...style }} className="row mx-0">
        <div
          className="col-12 col-xl-4 px-0"
          style={{
            width: `${100 * scale}px!important`,
          }}
        >
          <div style={styles.avatarContainer}>
            <ProfilePic scale={scale} avatar={avatar} entity={entity} />
            {allowEdit && (
              <div>
                <label htmlFor="profilePic">
                  <div style={styles.editContainer}>
                    <div style={styles.edit}>Edit</div>
                  </div>
                </label>
                <input
                  id="profilePic"
                  type="file"
                  className="invisible"
                  accept="image/*"
                  onChange={(e) => {
                    let conf = window.confirm(
                      "Are you sure you want to chage you profile picture?"
                    );
                    if (conf) {
                      let files = e.target.files || e.dataTransfer.files;
                      onEdit(files[0])
                      console.log('uploading...')
                      //store.updateProfilePic(files[0], false);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-12 col-lg-8">
          <div style={styles.avatarName}>{name}</div>
          <div style={styles.avatarPhone}>{phone}</div>
          <div style={styles.avatarSupportingText}>{supportingText}</div>
        </div>
      </div>
    );
  }
);

export default ProfileChip;
