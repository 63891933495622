import { Col, Row, Layout } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import ContactButtons from "./ContactButtons";
import ProfileChip from "./ProfileChip";

const ContactCard = observer((props) => {
  var data = props.data;
  if (!data) {
    data = { name: "Peter parker", type: "Technician" };
  }

  const age = data?.age;
  const gender = data?.gender;
  const ageAndGenderString =
    age && gender ? `${age}, ${gender}` : !age ? gender : !gender ? age : "";

  return (
    <Row
      style={{
        marginTop: "0px",
        fontFamily: "Nunito-bold",
        marginBottom: "10px",
        borderRadius: 30,
        padding: 10,
        border: "none",
        backgroundColor: "white",
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
      }}
      align="middle"
    >
      <Col span={24} style={{ textAlign: "center" }}>
        <h6>Contact Your {data?.type}</h6>
      </Col>
      <Col span={16}>
        <ProfileChip
          name={data.name}
          phone={data.phone}
          supportingText={`${ageAndGenderString}`}
          allowEdit={false}
          entity="contact"
        />
      </Col>
      <Col span={8}>
        <ContactButtons showPhone={false} showVideo={false} showChat={false} onChat={props.onChat} />
      </Col>
    </Row>
  );
});
export default ContactCard;
