import { Col, Row, Layout, Empty } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import RecentRxItem from "./RecentRxItem";
import { themestore } from "../dataLayer/stores/ThemeStore";
const RecentRx = observer(({ data, onClick = () => {} }) => {

  return (
    <Row style={{ padding: 20, minHeight: "30vh" }} className="drp-card">
      <h4 className="drp-font-1" style={{ fontWeight: "400", color: themestore.colors.maroon }}>Recent Prescriptions</h4>
      {data?.arr?.length > 0 ? (
        data.arr.map((item, index) => {
          return <RecentRxItem data={item} onClick={onClick} />;
        })
      ) : (
        <Empty style={{ marginTop: "16%", marginLeft: "-10%" }} />
      )}
    </Row>
  );
});
export default RecentRx;
