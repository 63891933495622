import { Image } from "react-bootstrap";
import pillPackEnrolledImage from "../../assets/images/pillPack.svg";

export const PillPackImage = ({ height, width }) => {
  return (
    <Image
      src={pillPackEnrolledImage}
      fluid
      height={height}
      width={width}
    ></Image>
  );
};
