
import _ from "lodash";
import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
} from "mobx";
import { vitalsActionsStore } from "../actions/vitalsActionsStore";
import { dbstore } from "../dbStore";
import { vitalsStore } from "./vitalsStore";

export default class Store {

  comp1 = {}
  comp2 = {}
  comp3 = {}
  constructor() {
    makeAutoObservable(this);
  }

  get comp1Data() {
    if (!dbstore.patientReports) {
      return
    }
    var obj = {};
    var s = vitalsActionsStore.comp1.selected
    var index = _.findIndex(dbstore.patientReports.vitalsAvg, {
      key: s,
    });

    obj = {
      name: vitalsStore.vitalMetadata[s].name,
      unit: vitalsStore.vitalMetadata[s].units,
      key: s,

      systolic: Math.floor(dbstore.patientReports.vitalsAvg[index].systolic),
      diastolic: Math.floor(dbstore.patientReports.vitalsAvg[index].diastolic),
      value: Math.floor(dbstore.patientReports.vitalsAvg[index].value),
    };

    Object.assign(this.comp1, obj);
    return this.comp1
  }
  get comp2Data() {
    return vitalsStore.comp2Data
  }
  get comp3Data() {
    return vitalsStore.comp3Data
  }
}

const vitalDetailsStore = new Store();
export { vitalDetailsStore };

