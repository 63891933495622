import { Col, Row, Empty } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { dbstore } from "../dataLayer/stores/dbStore";
import ComplianceBubble from "./ComplianceBubble";
import { PillPackImage } from "./reusableComponents/PillPackImage";
import _ from 'lodash';

const PtList = observer(({ data, totalPatients, onClick = () => {} }) => {

  const handleScroll = (e) => {
    if (totalPatients > data.arr.length) {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop - 1 <= e.target.clientHeight;

      if (bottom) {
        // after scrolling default user is getting changed fix it ASAP
        console.log("bottom reached");
        let skip = dbstore.patientsListQueryParams.skip + 1;
        dbstore.getPatients(true, skip);
        dbstore.setPatientsListQueryParams({ skip: skip });
      }
    }
  };

  function getPatientsCompliance(patientId) {
    let patientCompliancePercentage = _.find(dbstore.patientsComplianceArray, { _id: patientId })
    return patientCompliancePercentage?.compliancePercentage || 0
  }

  return (
    <>
      {data?.arr ? (
        <Row
          onScroll={handleScroll}
          style={{ overflowY: "scroll", paddingRight: 5, maxHeight: "82vh" }}
        >
          <Col span={24}>
            {data.arr.map((item, index) => {
              return (
                <Row
                  key={index}
                  onClick={() => {
                    onClick(item._id);
                  }}
                  style={{
                    backgroundColor: "rgb(244, 246, 249)",
                    borderRadius: 20,
                    margin: 10,
                    cursor: "pointer",
                    padding: 10,
                  }}
                >
                  <Col
                    className="gutter-row"
                    span={6}
                    style={{
                      alignItems: "start",
                    }}
                  >
                    <ComplianceBubble
                      showPercentage={true}
                      mini={true}
                      scale={1.2}
                      percentage={getPatientsCompliance(item._id)}
                    />
                  </Col>
                  <Col
                    className="gutter-row"
                    span={14}
                    style={{
                      fontWeight: 400,
                    }}
                  >
                    <p
                      style={{
                        color: "black",
                        fontSize: 15,
                        marginBottom: "5px",
                      }}
                    >
                      {item.pname}
                    </p>
                    {`${item.page}, ${item.pgender}`}
                  </Col>
                  <Col
                    className="gutter-row"
                    span={4}
                    style={{
                      textAlign: "end",
                    }}
                  >
                    {item.isPillPackEnrolled && (
                      <PillPackImage height={35} width={35} />

                      // <GiMedicinePills
                      //   id={`pillPackEnrolledId${index}`}
                      //   className="align-self-center fs-3"
                      //   color={Colours.maroon}
                      //   style={{ verticalAlign: "text-top" }}
                      // />
                    )}
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      ) : (
        <Empty />
      )}
    </>
  );
});
export default PtList;
