import { Row, Empty, Col, Space } from "antd";
import React from "react";
import { observer } from "mobx-react";
import PtInfo from "./PtInfo";
import PtRxList from "./PtRxList";
import { dbstore } from "../dataLayer/stores/dbStore";
import { PillPackImage } from "./reusableComponents/PillPackImage";

const PtDetails = observer(({ data }) => {
  return (
    <>
      <Row className="drp-card">
        <div
          style={{
            backgroundColor: "#F4F6F9",
            borderRadius: 10,
            width: "100%",
            padding: 20,
            maxHeight: "90vh",
          }}
        >
          {!(data?.ptinfo && data.ptrxlist) ? (
            <Empty />
          ) : dbstore.selectedPatientId ? (
            <>
              <Space
                direction="vertical"
                size="large"
                style={{
                  display: "flex",
                }}
              >
                <Row>
                  <Col span={20}>
                    <PtInfo data={data?.ptinfo} />
                  </Col>
                  <Col span={4} style={{ textAlign: "end", marginTop: 30 }}>
                    {data?.ptinfo?.isPillPackEnrolled && (
                      <PillPackImage height={50} width={50} />
                    )}
                  </Col>
                </Row>
                <Row>
                  <PtRxList data={{ arr: data?.ptrxlist }} />
                </Row>
              </Space>
            </>
          ) : (
            <Empty />
          )}
        </div>
      </Row>
    </>
  );
});
export default PtDetails;
