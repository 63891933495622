
import { Col, Row, Layout } from 'antd'
import { useEffect, useState } from 'react'
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
// import calendarStore from '../../dataLayer/patients';
import { observer } from 'mobx-react';
import { calendarStore } from '../../dataLayer/stores/pages/calendarStore';
import { dbstore } from '../../dataLayer/stores/dbStore';
import { useGetQueryStringParams } from '../../hooks/CustomHooks';



var box = {
  minHeight: '70vh',
  backgroundColor: "white",
  margin: "10em 5em",
  overflow: "scroll",
  padding: 30,
  // paddingRight:0,
  borderRadius: 50,
  maxWidth: "100%",
  // margin:"10em auto"
};
const PatientCalendar = observer(({ props }) => {
  const patientId = useGetQueryStringParams();
  const [calendarState, setcalendarState] = useState(null);

  useEffect(() => {
    var run = async () => {

      await calendarStore.getCalendarData(patientId)
      // document.addEventListener('DOMContentLoaded', function () {
      let calendarEl = document.getElementById('calendar');
      let calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin],
        // plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: ''
        },
        events: [],

      });
      setcalendarState(calendar)
      calendar.render();
      console.log('calendar rendered')
      calendar.setOption('events', calendarStore.calendarEvents)

      calendar.render();
    }

    run()
    // });
  }, [, dbstore.selectedPatientId]);

  useEffect(() => {
    if (calendarState) {

      calendarState.setOption('events', calendarStore.calendarEvents)
      calendarState.render()
      console.log('calendar re-rendered')
    }
  }, [calendarStore.calendarEvents])

  return (
    <>
      <Row style={{ width: '100%', padding: 0, }} gutter={[32, 32]}>
        <Col span={24}>
          <div style={box} id="calendar"></div>
        </Col>
      </Row>
    </>
  );
});
export default PatientCalendar;