import { Card, Col, Row } from "react-bootstrap";
import { useState } from 'react'
import PersonalInfo from "./PersonalInfo";
import ToggleSwitch from "./ToggleSwitch";

const SettingsCard = ({ data, submit = () => { } }) => {
  if (!data) {
    data = {
      repeatReminders: false,
      eodReminders: false
    }
  }

  return (
    <Card
      style={{
        marginTop: "10px",
        marginBottom: "10px",
        borderRadius: 30,
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
      }}
    >
      <Card.Body>
        <ToggleSwitch
          defaultChecked={data.repeatReminders}
          label={"Repeat Reminders"}
          className={"mt-2 mb-3 justify-content-between "}
          onChange={(val) => {
            data.repeatReminders = val;
            console.log(data)
            submit("repeatReminders", val);
          }}
        />
        <ToggleSwitch
          defaultChecked={data.eodReminders}
          label={"End of Day Reminders"}
          className={"mt-3 justify-content-between "}
          onChange={(val) => {
            data.eodReminders = val;
            console.log(data)
            submit("eodReminders", val);
          }}
        />
        <Row className={"mt-3 justify-content-between align-items-center"}>
          <Col xs={12} lg={5} xl={4}>
            <span style={{ fontSize: 18 }}>Status: </span>
          </Col>
          <Col xs={12} xl={8}>
            <PersonalInfo
              FIcon={() => <></>}
              textContent={data.status ? data.status : 'active'}
              iconSize={20}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
export default SettingsCard;
