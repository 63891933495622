import { Col, Row, Layout, Empty } from "antd";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { pauthActionStore } from "../../dataLayer/stores/actions/pauthActionStore";
import { pauthStore } from "../../dataLayer/stores/pages/pauthStore";
import PAList from "../PAList";
import RxDetails from "../RxDetails";
import Triangle from "../Triangle";
var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const PriorAuth = observer(({ props }) => {
  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={8}>
          <div
            style={{ backgroundColor: "#fff", height: "80vh", marginTop: 15 }}
            className="drp-card"
          >
            {pauthStore?.comp1Data?.arr?.length > 0 ? (
              <PAList
                data={pauthStore.comp1Data}
                onClick={(id) => {
                  pauthActionStore.setSelected(id);
                }}
              />
            ) : (
              <Empty style={{marginTop: "50%"}} description="You do not have any Pending Prior Authorizations" />
            )}
          </div>
        </Col>
        <Col span={16}>
          <div
            style={{
              backgroundColor: "#fff0",
              height: "80vh",
              marginTop: 15,
              marginLeft: 10,
            }}
          >
            <Row style={{ padding: 20 }} className="drp-card">
              {pauthStore.comp2Data ? (
                <RxDetails data={pauthStore.comp2Data} />
              ) : (
                <Empty style={{marginTop: "24%", marginLeft: "37%"}} />
              )}
              <Triangle
                style={{
                  position: "absolute",
                  top: "25%",
                  left: 0,
                }}
              />
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
});
export default PriorAuth;
