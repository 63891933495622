import { Col, Row, Layout, Button, Space, message } from "antd";
import { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react";
import { Colours } from "../Colours";
import { dbstore } from "./../dataLayer/stores/dbStore";
import { profileStore } from "./../dataLayer/stores/pages/profileStore";
import SignaturePad from "react-signature-canvas";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Image } from "react-bootstrap";
import { b64toBlob, getBase64FromDataURL } from "../Common.fns";

const SignAndApprove = observer(({ data }) => {
  let history = useHistory();
  const [signature, setsignature] = useState(null);
  const [showSign, setshowSign] = useState(true);
  const [showloader, setshowloader] = useState(true);
  const [imageURL, setImageURL] = useState(null);
  useEffect(() => {
    if (profileStore.comp1Data?.signature) {
      setshowSign(false);
      setImageURL(profileStore.comp1Data?.signature);
    }
  }, [profileStore.comp1Data.signature]);

  const sigCanvas = useRef({});
  /* a function that uses the canvas ref to clear the canvas 
  via a method given by react-signature-canvas */
  const clear = () => {
    if (!showSign) {
      setshowSign(true);
    } else {
      sigCanvas.current.clear();
    }
  };

  /* a function that uses the canvas ref to trim the canvas 
  from white spaces via a method given by react-signature-canvas
  then saves it in our state */
  const save = async () => {
    let image = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    let base64 = getBase64FromDataURL(image);
    let blob = await b64toBlob(base64, "application/json");
    var res = await dbstore.newSigPic(blob);
    if (res == 200) {
      console.log("success");
      message.success("Upload Success");
    } else {
      console.log("Failure");
      message.error("Oops! something went wrong try again");
    }
  };
  const styles = {
    picture: { height: 100 * 1, width: 100 * 1 },
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100vh",
          paddingTop: 60,
          borderRadius: 25,
          backgroundColor: "white",
          padding: 15,
          textAlign: "center",
        }}
      >
        <Row>
          <Col span={24} style={{ textAlign: "initial" }}>
            <ArrowLeftOutlined
              title="Back"
              style={{ fontSize: "32px" }}
              onClick={() => history.push("/refills")}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <h4>Sign & Approve</h4>

            {/* <Image onError={() => { setshowSign(true) }} onLoad={() => { setshowloader(false) }} source={{ uri: 'https://media.istockphoto.com/photos/open-book-close-up-at-the-library-picture-id1302676874' }} style={{
          width: '100%', height: '100%'
        }} /> */}
            {!showSign ? (
              <Image
                height={400}
                width={400}
                onError={(e) => {
                  console.error("error", e);
                }}
                src={profileStore.comp1Data?.signature}
                alt="Signature"
              />
            ) : (
              <SignaturePad
                ref={sigCanvas}
                canvasProps={{
                  width: 400,
                  height: 400,
                  className: "sigCanvas",
                }}
                backgroundColor="#F5F5F5"
              />
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: 40 }}>
          <Col span={24}>
            <Space>
              <Button
                type="ghost"
                style={{
                  width: 120,
                  borderRadius: 25,
                }}
                size="large"
                onClick={async () => {
                  clear();
                }}
              >
                Clear Signature
              </Button>
              <Button
                style={{
                  backgroundColor: Colours.green,
                  color: "white",
                  width: 120,
                  borderRadius: 25,
                }}
                size="large"
                onClick={async () => {
                  console.log(" taking sign ");
                  save();
                }}
              >
                Submit
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    </>
  );
});
export default SignAndApprove;
