import { Select } from "grommet";
import { inject, observer } from "mobx-react";
import { useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { Row, Col } from "antd";
import { Colours } from "../Colours";
import ComplianceChart from "./ComplianceChart.js";
import { find } from "lodash";
import { propTypes } from "react-bootstrap/esm/Image";
// import { store } from "../dataLayer/stores/store";
// import { homeStore } from "../dataLayer/stores/pages/homeStore";
import { homeActionStore } from "../dataLayer/stores/actions/homeActionStore";


const ComplianceChartCard = observer((props) => {

  const optionsList = useRef([
    { value: 7, description: "Last 7 days" },
    { value: 30, description: "Last 30 days" },
    { value: 60, description: "Last 60 days" },
    { value: 90, description: "Last 90 days" },
  ]);


  const [value, setValue] = useState(
    find(optionsList.current, (o) => o.value === props.data.timePeriod)
  );
  // console.log({ props });
  return (
    <Card
      style={{

        ...props.style,
        width: "100%",
        marginTop: "10px",
        marginBottom: "10px",
        borderRadius: 30,
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
        minHeight: 300,
      }}
    >
      <Card.Body>
        <Row className={"justify-content-between pl-3 pr-3"}>
          <span
            style={{
              fontFamily: "SourceSansPro-Regular",
              fontSize: 18,
              color: Colours.secondaryText,
            }}
          >
            Your Pill Compliance
          </span>
          <Select
            options={optionsList.current}
            value={value}
            onChange={({ option }) => {
              console.log(option);
              // uiStore.homeChartDuration = option.value;

              setValue(option);
              homeActionStore.setComp2({period: option.value})
            }}
            valueKey="value"
            labelKey="description"
          />
        </Row>
        <Row>
          <ComplianceChart data={props.data} height={props.chartHeight} />

        </Row>
      </Card.Body>
    </Card>
  );
});

export default ComplianceChartCard;
