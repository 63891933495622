
import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
} from "mobx";
import { dbstore } from "../dbStore";
import { homeStore } from "./homeStore";

export default class Store {

  comp1 = {}
  comp2 = {}
  comp3 = {}
  constructor() {
    makeAutoObservable(this);
  }

  get comp1Data() {
    return homeStore.comp2Data
  }
  get comp2Data() {
    return homeStore.comp1Data
  }
  get comp3Data() {
    var obj = {}
    if (!dbstore.doctorReports) {
      return
    }
    var ptd = dbstore.doctorReports.patientPcGrouping;
    obj = {
      lowCompliant: Math.floor(ptd.lowCompliant / ptd.total * 100),
      mediumCompliant: Math.floor(ptd.mediumCompliant / ptd.total * 100),
      wellCompliant: Math.floor(ptd.wellCompliant / ptd.total * 100),
    }
    Object.assign(this.comp3, obj)

    return this.comp3
  }
}

const reportsStore = new Store();
export { reportsStore };

