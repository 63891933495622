import { Col, message, Row, Spin } from "antd";
import React from "react";
import { observer } from "mobx-react";
import ComplianceCard from "../ComplianceCard";
import SmallInfoCard from "../SmallInfoCard";
import { useHistory } from "react-router-dom";
import RecentPatients from "../RecentPatients";
import RecentRx from "../RecentRx";
import { homeStore } from "../../dataLayer/stores/pages/homeStore";
import { dbstore } from "../../dataLayer/stores/dbStore";
import _ from "lodash";
import Cookies from "js-cookie";
import moment from "moment";
import { useEffect } from "react";

var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const Home = observer(({ props }) => {
  let history = useHistory();

  useEffect(() => {
    let doctorComplianceLastFetchedOn = Cookies.get(
      "doctorComplianceLastFetchedOn"
    );

    if (
      !doctorComplianceLastFetchedOn ||
      moment().utc(true).diff(moment(doctorComplianceLastFetchedOn), "days") > 1
    ) {
      dbstore.getDoctorPillCompliance();
    }
  }, []);

  return !dbstore.globalRefresh ? (
    <>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          {/* <Row>
            <p
              className="m-2"
              style={{
                fontFamily: "Nunito-SemiBold",
                fontSize: "28px",
                color: "#272D5C",
              }}
            >
              Overview
            </p>
          </Row> */}
          <Row>
            <Col span={12}>
              <div style={box}>
                <ComplianceCard data={homeStore.comp1Data} />
              </div>
            </Col>
            {/* <Col span={16}>
              <div style={box}>
                {console.log(homeStore.comp2Data)}
                <ComplianceChartCard
                  data={
                    homeStore.comp2Data
                    // {
                    //   // graphData: homeStore.comp2Data.graphdata,
                    //   graphData: [],
                    //   timePeriod: 30,
                    // }
                  }
                />
              </div>
            </Col> */}
            <Col span={12}>
              <Row>
                <Col span={24}>
                  <div style={box}>
                    <SmallInfoCard
                      onClick={() => {
                        if (homeStore.comp3Data === 0) {
                          message.info(
                            "You do not have any Pending Refill Requests"
                          );
                        } else {
                          history.push("refills");
                        }
                      }}
                      data={{
                        info: `You have ${homeStore.comp3Data} pending`,
                        highlight: "Refill Requests",
                        count: homeStore.comp3Data,
                        buttonText: "Complete Now",
                        disableButton: homeStore.comp3Data === 0,
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div style={box}>
                    <SmallInfoCard
                      onClick={() => {
                        if (homeStore.comp4Data === 0) {
                          message.info(
                            "You do not have any Pending Prior Authorizations"
                          );
                        } else {
                          history.push("priorAuth");
                        }
                      }}
                      data={{
                        info: `You have ${homeStore.comp4Data} pending`,
                        highlight: "Prior-Auths",
                        count: homeStore.comp4Data,
                        buttonText: "Complete Now",
                        disableButton: homeStore.comp4Data === 0,
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {/* <Col span={8}>
          <div style={{ height: "85%", ...box }}>
            <SmallInfoCard
              onClick={() => {
                history.push("technicianReports");
              }}
              data={{
                info: "Technician Reports",
                highlight: " ",
                buttonText: "View Now",
                count: 0,
              }}
            />
          </div>
        </Col> */}
        <Col span={12}>
          <div style={box}>
            <RecentPatients
              data={homeStore.comp6Data}
              onClick={(id) => {
                history.push(`/patients?patientId=${id}`);
                dbstore.setSelectedPatientId(id);
              }}
            />
          </div>
        </Col>
        <Col span={12}>
          <div style={box}>
            <RecentRx
              data={homeStore.comp7Data}
              onClick={(id) => {
                history.push(`/patients?patientId=${id}`);
                dbstore.setSelectedPatientId(id);
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  ) : (
    <Spin
      style={{ marginTop: "25%", marginLeft: "50%" }}
      tip="Loading..."
      spinning={dbstore.globalRefresh}
    ></Spin>
  );
});
export default Home;
