import { Col, Row, Layout, Empty } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Card } from "react-bootstrap";
import { Colours } from "../Colours";
import moment from "moment";
import { dbstore } from "../dataLayer/stores/dbStore";
import { vitalsActionsStore } from "../dataLayer/stores/actions/vitalsActionsStore";
import { vitalsStore } from "../dataLayer/stores/pages/vitalsStore";

const VitalRecords = observer(({ data, limit = null }) => {
  const styles = {
    itemContainer: {
      width: "100%",
      color: Colours.primaryText,
      display: "flex",
      justifyContent: "space-around",
      flexDirection: "row",
      fontSize: 18,
      lineHeight: 2.5,
    },
    itemChild: { flex: 0.5, textAlign: "center" },
  };
  const renderItem = (datum, index) => {
    return (
      <div key={index} style={styles.itemContainer}>
        <div style={styles.itemChild}>
          {data.key === "bloodPressure"
            ? `${datum.systolic} | ${datum.diastolic}`
            : data.key === "bloodGlucose"
            ? `${datum.value}(${(datum.currentStatus || "")
                .split("-")
                .join(" ")})`
            : `${datum.value}`}
        </div>
        <div style={styles.itemChild}>
          {moment(Number(datum.date)).format("DD/MM/Y")}
        </div>
      </div>
    );
  };
  return (
    <Card
      style={{
        borderRadius: 30,
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
        border: "none",
        height: "100%",
      }}
    >
      <Card.Body>
        <div style={styles.itemChild}>
          {vitalsStore.vitalMetadata[vitalsActionsStore.comp1.selected].name}
        </div>
        <div
          style={{
            width: "100%",
            color: Colours.primaryText,
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "row",
            fontSize: 18,
            lineHeight: 3,
            borderBottom: "1px black solid",
          }}
        >
          <div style={styles.itemChild}>Record</div>
          <div style={styles.itemChild}>Date</div>
        </div>
        {data?.values?.length > 0 ? (
          data.values
            .slice(0, limit ? limit : data.values.length)
            .map((item, index) => {
              return renderItem(item, index);
            })
        ) : (
          <Empty
            style={{ marginTop: "7%", minHeight: "20vh" }}
            description="No Records available"
          />
        )}
      </Card.Body>
    </Card>
  );
});
export default VitalRecords;
