import { observer } from "mobx-react";
import { useCallback, useEffect } from "react";
import { Card, Image } from "react-bootstrap";
import VitalIconBp from "../assets/images/vitals-icon-bp@2x.png";
import { Col } from "antd";
const VitalInfoCard = observer(
  ({ data, vitalKey, onClick }) => {
    if (!data) {
      data = {
        vitalsAverage: {
          bloodPressure: {
            name: "Blood Pressure",
            systolic: 0,
            diastolic: 0,
            units: "cmHg",
          },
          bloodGlucose: {
            name: "Blood Glucose",
            value: 0,
            units: "mgdl",
          },
          temperature: {
            name: "Temperature",
            value: 0,
            units: "F",
          },
          weight: {
            name: "Weight",
            value: 0,
            units: "lbs",
          },
          oxygenLevels: {
            name: "Oxygen Levels",
            value: 0,
            units: "mmHg",
          },
        },
      };
    }
    var metaData = {
      vitalMetadata: {
        bloodPressure: {
          name: "Blood Pressure",
          units: "cmHg",
        },
        bloodGlucose: {
          name: "Blood Glucose",
          units: "mgdl",
        },
        temperature: {
          name: "Temperature",
          units: "F",
        },
        weight: {
          name: "Weight",
          units: "lbs",
        },
        oxygenLevels: {
          name: "Oxygen Levels",
          units: "mmHg",
        },
      },
    };

    const styles = {
      vitalText: {
        fontFamily: "Nunito-SemiBold",
        fontSize: 18,
        color: "white",
      },
      vitalValue: {
        fontFamily: "Nunito-SemiBold",
        fontSize: 32,
        color: "white",
      },
      bloodPressure: {
        backgroundImage: "linear-gradient(to top right, #FE8B6E , #FF6773)",
      },
      bloodGlucose: {
        backgroundImage: "linear-gradient(to top right, #9F64A8 , #6F66D1)",
      },
      temperature: {
        backgroundImage: "linear-gradient(to top right, #61C2D2 , #0B82BA)",
      },
      weight: {
        backgroundImage: "linear-gradient(to top right, #9692E9 , #574FFA)",
      },
      oxygenLevels: {
        backgroundImage: "linear-gradient(to top right, #FFB75E , #ED8F03)",
      },
    };

    const getHelpfulTip = useCallback(() => {
      switch (vitalKey) {
        case "bloodPressure":
          return "Ideal Blood Pressure 120|80 cmHg";
        case "bloodGlucose":
          return "Ideal Blood Glucose 80-130 mgdl";
        case "temperature":
          return "5F higher than normal";
        case "weight":
          return "Ideal body weight";
        case "oxygenLevels":
          return "Ideal oxygen level";
        default:
          return "Test";
      }
    }, [vitalKey]);

    return (
      <Col span={4} offset={.5}>
        <Card
          draggable={true}
          className="px-2"
          style={{
            ...{
              borderRadius: 30,
              boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
              border: "none",
              cursor: "pointer",
              height: "95%",
            },
            ...styles[vitalKey],
          }}
          onClick={() => onClick(vitalKey)}
        >
          <Card.Body>
            <div className={" mx-0 mb-1 mb-lg-3 justify-content-between"}>
              <Image height={50} src={VitalIconBp} />
            </div>
            <div className="mb-2" style={styles.vitalText}>
              {metaData.vitalMetadata[vitalKey].name}
            </div>
            <div className="mb-2" style={styles.vitalValue}>
              {vitalKey === "bloodPressure"
                ? `${Math.floor(data.vitalsAverage?.bloodPressure?.systolic) || '-'}|${Math.floor(data.vitalsAverage?.bloodPressure?.diastolic) || '-'
                }`
                : Math.floor(data.vitalsAverage[vitalKey]?.value) || '-'}
            </div>
            <div className="mb-3" style={styles.vitalText}>
              {metaData.vitalMetadata[vitalKey]?.units || '-'}
            </div>
            <div className="mt-4 mb-3" style={styles.vitalText}>
              {getHelpfulTip()}
            </div>
          </Card.Body>
        </Card>
      </Col>
    );
  }
);

export default VitalInfoCard;
